import { createSlice } from "@reduxjs/toolkit";

import {
  getCompanies,
  addCompany,
  editCompany,
  activateCompany,
  deactivateCompany,
  deleteCompany,
} from "./thunk";

export const initialState = {
  companyList: [],
  error: {},
};

const CompanySlice: any = createSlice({
  name: "Company",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    builder.addCase(getCompanies.fulfilled, (state: any, action: any) => {
      state.companyList = action.payload;
    });

    builder.addCase(getCompanies.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    // add
    builder.addCase(addCompany.fulfilled, (state: any, action: any) => {
      state.companyList.push(action.payload?.data);
    });
    builder.addCase(addCompany.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(editCompany.fulfilled, (state: any, action: any) => {
      state.companyList = state.companyList.map((company: any) =>
        company.id === action.payload.id
          ? { ...company, ...action.payload }
          : company
      );
    });

    builder.addCase(editCompany.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(activateCompany.fulfilled, (state: any, action: any) => {
      state.companyList = state.companyList.map((company: any) =>
        company.id === action.payload?.data?.id
          ? { ...company, ...action.payload?.data }
          : company
      );
    });

    builder.addCase(activateCompany.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(deactivateCompany.fulfilled, (state: any, action: any) => {
      console.log('action.payload', action.payload)
      state.companyList = state.companyList.map((company: any) =>
        company.id === action.payload?.data?.id
          ? { ...company, ...action.payload?.data }
          : company
      );
    });

    builder.addCase(deactivateCompany.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });
    builder.addCase(deleteCompany.fulfilled, (state: any, action: any) => {
      state.companyList = (state.companyList || []).filter(
        (company: any) => company.id !== action.payload.id
      );
    });
    builder.addCase(deleteCompany.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });
  },
});

export default CompanySlice.reducer;
