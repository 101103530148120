import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  tokens: {},
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  reloaded: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiLoading(state) {
      state.loading = true;
      state.errorMsg = false;
    },
    apiError(state, action) {
      console.log('action.payload.data', action.payload)
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      console.log( 'state, action', state.user, action)
      state.user = action.payload?.data
      state.tokens = action.payload?.tokens
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
    },
    reset_login_flag(state) {
      state.error = ""
      state.loading = false;
      state.errorMsg = false;
      state.reloaded = false;
    }
  },
});

export const {
  apiLoading,
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag
} = loginSlice.actions

export default loginSlice.reducer;