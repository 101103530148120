import { recentTrasaction, salesRevenue, invoiceList } from "./dashboard";

import { companiesList } from "./transaction";
import { usersList } from "./users";
import {
  wasteNotificationWidget,
  clientWasteNotificationList,
  walletsWidget
} from "./wasteNotifications";

export {
  recentTrasaction,
  salesRevenue,
  invoiceList,
  companiesList,
  usersList,
  wasteNotificationWidget,
  clientWasteNotificationList,
  walletsWidget
};
