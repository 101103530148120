import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { editOilPlatform as onEditOilPlatform } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

interface oilplatformeditProps {
  isShow: any;
  handleClose: any;
  edit: any;
  // isEdit: any
}

const EditOilPlatform = ({
  isShow,
  handleClose,
  edit,
}: oilplatformeditProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (edit && edit.id) || "",
      name: (edit && edit.name) || "",
      // date: (edit && edit.date) || "",
      longitude: (edit && edit.longitude) || "",
      latitude: (edit && edit.latitude) || "",
      address: (edit && edit.address) || "",
      status: (edit && edit.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Member Name"),
      longitude: Yup.string().required("Please Select longitude"),
      latitude: Yup.string().required("Please Enter latitude"),
      address: Yup.string().required("Please Enter your address"),
      // amount: Yup.string().required("Please Enter amount"),
      // status: Yup.string().required("Please choose Your status"),
    }),

    onSubmit: (values: any) => {
      setLoading(true);
      const UpdateOilPlatform = {
        id: edit.id,
        name: values.name,
        longitude: values.longitude,
        latitude: values.latitude,
        address: values.address,
        // status: edit.status,
      };

      dispatch(onEditOilPlatform(UpdateOilPlatform));
      formik.resetForm();
    },
  });

  const selectStates = createSelector(
    (state: any) => state.OilPlatform,
    (OilPlatform: any) => ({
      oilPlatformList: OilPlatform.oilPlatformList,
      error: OilPlatform.error,
    })
  );

  const { oilPlatformList, error } = useSelector(selectStates);

  useEffect(() => {
    if (loading && oilPlatformList) {
      console.log('error', error)
      handleClose();
      setLoading(false);
    }
  }, [error, handleClose, loading, oilPlatformList]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Edit OilPlatform Details</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Name</Form.Label>
                <Form.Control
                  type="text"
                  id="memberName"
                  name="name"
                  placeholder="Enter platform name"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="Name">Address</Form.Label>
                <Form.Control
                  id="address"
                  name="address"
                  placeholder="Enter address"
                  value={formik.values.address || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.address}
                />
                {formik.errors.address && formik.touched.address ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Latitude</Form.Label>
                    <Form.Control
                      id="latitude"
                      name="latitude"
                      type="number"
                      placeholder="Enter latitude"
                      value={formik.values.latitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.latitude}
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.latitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Longitude</Form.Label>
                    <Form.Control
                      id="longitude"
                      name="longitude"
                      type="number"
                      placeholder="Enter longitude"
                      value={formik.values.longitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.longitude}
                    />
                    {formik.errors.longitude && formik.touched.longitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.longitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                {/* <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <Form.Select
                      id="oilplatformType"
                      name="status"
                      placeholder="Enter OilPlatform type"
                      value={formik.values.status || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.status}
                    >
                      <option>Select Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                      <option value="Failed">Failed</option>
                    </Form.Select>
                    {formik.errors.status && formik.touched.status ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.status}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col> */}
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Edit OilPlatform
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditOilPlatform;
