import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../../Common/DeleteModal";
import { Link } from "react-router-dom";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import {
  getClientWasteNotifications as onGetClientWasteNotifications,
  deleteClientWasteNotifications as onDeleteClientWasteNotifications,
} from "../../../slices/thunk";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import EditWasteNotification from "../../../Common/CrudModal/EditWasteNotification";
import WasteNotificationWidget from "./NotificationWidget";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import WasteNotificationChatModal from "../../../Common/CrudModal/WasteNotificationChatModal";
import { InspectionStatusEnum, VisitationRequisitionStatusEnum, WasteCollectionStatusEnum } from "../../../helpers/enums/visitRequisition.enum";
import { PaymentStatusEnum } from "../../../helpers/enums/bill.enum";

const WasteNotificationTable = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const selectClientWasteNotificationList = createSelector(
    (state: any) => state.WasteNotification,
    (state: any) => state.Login,
    (wasteNotifications: any, login: any) => ({
      clientWasteNotificationsList:
        wasteNotifications.clientWasteNotificationsList,
      loggedInUser: login.user,
      company: login.user.company,
    })
  );

  const { clientWasteNotificationsList, company, loggedInUser } = useSelector(
    selectClientWasteNotificationList
  );

  const [wasteNotifications, setWasteNotifications] = useState<any>([]);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(loggedInUser?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? [child] : [];
  };

  useEffect(() => {
    dispatch(onGetClientWasteNotifications());
  }, [dispatch]);

  useEffect(() => {
    setWasteNotifications(clientWasteNotificationsList);
  }, [clientWasteNotificationsList]);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );

  const handleDeleteId = () => {
    dispatch(onDeleteClientWasteNotifications(deletid.id));
    setDelet(false);
  };

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value.toLowerCase();

    if (item === "All Tasks") {
      setWasteNotifications([...clientWasteNotificationsList]);
    } else {
      handleSearchData({
        data: clientWasteNotificationsList,
        item: item,
        setState: setWasteNotifications,
      });
    }
  };

  // edit data

  const [editWasteNotifications, setEditWasteNotifications] =
    useState<boolean>(false);

  const [chatWasteNotifications, setChatWasteNotifications] =
    useState<boolean>(false);
  const [edit, setEdit] = useState<any>();
  const handleCloseChat = () => setChatWasteNotifications(false);

  const handleCloseEdit = () => setEditWasteNotifications(false);
  const handleEditWasteNotifications = (item: any) => {
    setEditWasteNotifications(true);
    setEdit({
      id: item.id,
      wasteNotificationId: item.wasteNotificationId,
      clientImage: item.clientImage,
      clientName: item.clientName,
      email: item.email,
      date: item.date,
      billed: item.billed,
      status: item.status,
    });
  };

  const handleChatWasteNotifications = (item: any) => {
    setChatWasteNotifications(true);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const onRowClick = (cell: any) => {
    console.log("cell", cell);
    navigate(`/waste-notification-details/${cell.row.original?.id}`);
  };

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: () => (
          <div className="d-flex">
            <Form className="me-2">
              <Form.Check type="checkbox" />
            </Form>
            <div>WASTE NOTIFICATION REF NO.</div>
          </div>
        ),
        accessor: "id",
        key: "id",
        Filter: false,
        isSortable: false,
        width: 50,
        Cell: (cell: any) => (
          <div className="d-flex">
            <Form className="me-2">
              <Form.Check type="checkbox" />
            </Form>
            <Link
              className="text-body"
              to={`/waste-notification-details/${cell.row.original.id}`}
            >
              {String(cell.row.original?.id).substring(0, 13).toUpperCase()}
            </Link>
          </div>
        ),
      },
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        ],
        {
          Header: "COMPANY",
          accessor: "company.name",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            const item = cell.row.original;
            return (
              <div>
                <span className="text-body align-middle fw-medium">
                  {item.company.name}
                </span>
              </div>
            );
          },
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        ],
        {
          Header: "PLATFORM",
          accessor: "oilPlatform.name",
          Filter: false,
          isSortable: true,
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
          CompanyTypeEnum.SHIPMENT,
        ],
        {
          Header: "SHIP",
          accessor: "ship.name",
          Filter: false,
          isSortable: true,
        }
      ),
      {
        Header: "BILLED AMOUNT",
        accessor: "bill.totalTransactionValue",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          const item = cell.row.original;
          return (
            <Link
              className="text-body"
              to={`/waste-notification-details/${item.id}`}
            >
              {`₦${(
                +cell.row.original?.bill?.totalTransactionValue || 0
              ).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
              })}`}
            </Link>
          );
        },
      },
      // ...addLayoutData(
      //   [
      //     CompanyTypeEnum.PLATFORM_ADMIN,
      //     CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
      //     CompanyTypeEnum.SHIPMENT,
      //   ],
      //   {
      //     Header: "STATUS",
      //     accessor: "status",
      //     Filter: false,
      //     isSortable: true,
      //     Cell: (cell: any) => {
      //       switch (cell.row.original.status) {
      //         case VisitationRequisitionStatusEnum.COMPLETED:
      //           return (
      //             <span className="badge bg-success-subtle text-success p-2">
      //               {cell.row.original.status}
      //             </span>
      //           );
      //         case VisitationRequisitionStatusEnum.PENDING:
      //           return (
      //             <span className="badge bg-warning-subtle text-warning p-2">
      //               {cell.row.original.status}
      //             </span>
      //           );
      //         case VisitationRequisitionStatusEnum.CANCELLED:
      //           return (
      //             <span className="badge bg-danger-subtle text-danger p-2">
      //               {cell.row.original.status}
      //             </span>
      //           );
      //         default:
      //           return (
      //             <span className="badge bg-success-subtle text-success p-2">
      //               {cell.row.original.status}
      //             </span>
      //           );
      //       }
      //     },
      //   }
      // ),
      ...addLayoutData(
        [
          CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
        ],
        {
          Header: "STATUS",
          accessor: "wasteCollectionStatus",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            switch (cell.row.original.wasteCollectionStatus) {
              case WasteCollectionStatusEnum.CollectionCompleted:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
              case WasteCollectionStatusEnum.AwaitingCollection:
              case WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation:
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
              case WasteCollectionStatusEnum.CollectionScheduled:
              case WasteCollectionStatusEnum.AdjustmentConfirmed:
                return (
                  <span className="badge bg-info-subtle text-info p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
              default:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
            }
          },
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.SHIPMENT,
          CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        ],
        {
          Header: "COLLECTION STATUS",
          accessor: "wasteCollectionStatus",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            switch (cell.row.original.wasteCollectionStatus) {
              case WasteCollectionStatusEnum.CollectionCompleted:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
                case WasteCollectionStatusEnum.AwaitingCollection:
                case WasteCollectionStatusEnum.AwaitingAdjustmentConfirmation:
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
                case WasteCollectionStatusEnum.CollectionScheduled:
                case WasteCollectionStatusEnum.AdjustmentConfirmed:
                return (
                  <span className="badge bg-info-subtle text-info p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
              default:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.wasteCollectionStatus}
                  </span>
                );
            }
          },
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        ],
        {
          Header: "STATUS",
          accessor: "inspectionStatus",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            switch (cell.row.original.inspectionStatus) {
              case InspectionStatusEnum.InspectionCompleted:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              case InspectionStatusEnum.AwaitingInspection:
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              case InspectionStatusEnum.InspectionScheduled:
                return (
                  <span className="badge bg-info-subtle text-info p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              default:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
            }
          },
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.SHIPMENT,
        ],
        {
          Header: "INSPECTION STATUS",
          accessor: "inspectionStatus",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            switch (cell.row.original.inspectionStatus) {
              case InspectionStatusEnum.InspectionCompleted:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              case InspectionStatusEnum.AwaitingInspection:
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              case InspectionStatusEnum.InspectionScheduled:
                return (
                  <span className="badge bg-info-subtle text-info p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
              default:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.inspectionStatus}
                  </span>
                );
            }
          },
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
          CompanyTypeEnum.SHIPMENT,
          CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        ],
        {
          Header: "PAYMENT STATUS",
          accessor: "paymentStatus",
          Filter: false,
          isSortable: true,
          Cell: (cell: any) => {
            switch (cell.row.original.paymentStatus) {
              case PaymentStatusEnum.PaymentCompleted:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.paymentStatus}
                  </span>
                );
              case PaymentStatusEnum.AwaitingPayment:
                return (
                  <span className="badge bg-warning-subtle text-warning p-2">
                    {cell.row.original.paymentStatus}
                  </span>
                );
              case "cancelled":
                return (
                  <span className="badge bg-danger-subtle text-danger p-2">
                    {cell.row.original.paymentStatus}
                  </span>
                );
              case "Refund":
                return (
                  <span className="badge bg-info-subtle text-info p-2">
                    {cell.row.original.paymentStatus}
                  </span>
                );
              default:
                return (
                  <span className="badge bg-success-subtle text-success p-2">
                    {cell.row.original.paymentStatus}
                  </span>
                );
            }
          },
        }
      ),
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Filter: false,
      //   isSortable: false,
      //   Cell: (cell: any) => (
      //     <Dropdown>
      //       <Dropdown.Toggle
      //         as="button"
      //         className="btn btn-soft-secondary btn-sm arrow-none"
      //       >
      //         <i className="las la-ellipsis-h align-middle fs-18"></i>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu className="dropdown-menu-end">
      //         <li>
      //           <Dropdown.Item
      //             href={
      //               process.env.PUBLIC_URL +
      //               "/waste-notification-details/" +
      //               cell.row.original.id
      //             }
      //             // onClick={() => {
      //             //   const item = cell.row.original;
      //             //   handleViewWasteNotifications(item);
      //             // }}
      //           >
      //             <i className="las la-eye fs-18 align-middle me-2 text-muted"></i>
      //             View
      //           </Dropdown.Item>
      //         </li>
      //         {company.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
      //         company.companyType === CompanyTypeEnum.SHIPMENT ||
      //         company.companyType === CompanyTypeEnum.PLATFORM_ADMIN ? (
      //           <li>
      //             <Dropdown.Item
      //               onClick={() => {
      //                 const item = cell.row.original;
      //                 handleEditWasteNotifications(item);
      //               }}
      //             >
      //               <i className="las la-pen fs-18 align-middle me-2 text-muted"></i>
      //               Edit
      //             </Dropdown.Item>
      //           </li>
      //         ) : null}
      //         <li>
      //           <Dropdown.Item
      //             onClick={() => {
      //               const item = cell.row.original;
      //               handleChatWasteNotifications(item);
      //             }}
      //           >
      //             <i className="ri-question-answer-line align-middle me-2 text-muted"></i>
      //             Chats
      //           </Dropdown.Item>
      //         </li>
      //         {/* {company.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY ||
      //         company.companyType === CompanyTypeEnum.PLATFORM_ADMIN ? (
      //           <li>
      //             <Dropdown.Item
      //               onClick={() => {
      //                 const item = cell.row.original;
      //                 handleInspectionWasteNotifications(item);
      //               }}
      //             >
      //               <i className="las la-pen fs-18 align-middle me-2 text-muted"></i>
      //               Carry-out Inspection
      //             </Dropdown.Item>
      //           </li>
      //         ) : null} */}

      //         {/* {company.companyType ===
      //           CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
      //         company.companyType ===
      //           CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
      //         company.companyType === CompanyTypeEnum.PLATFORM_ADMIN ? (
      //           <li>
      //             <Dropdown.Item
      //               onClick={() => {
      //                 const item = cell.row.original;
      //                 handleCollectionWasteNotifications(item);
      //                 // handleEditWasteNotifications(item);
      //               }}
      //             >
      //               <i className="las la-pen fs-18 align-middle me-2 text-muted"></i>
      //               Collect Wastes
      //             </Dropdown.Item>
      //           </li>
      //         ) : null} */}

      //         <li>
      //           <Dropdown.Item to="#">
      //             <i className="las la-file-download fs-18 align-middle me-2 text-muted"></i>
      //             Download
      //           </Dropdown.Item>
      //         </li>
      //         <li className="dropdown-divider"></li>
      //         <li>
      //           <Dropdown.Item
      //             className="remove-item-btn"
      //             to="#"
      //             onClick={() => {
      //               const item = cell.row.original;
      //               handleDeleteModal(item);
      //             }}
      //           >
      //             <i className="las la-trash-alt fs-18 align-middle me-2 text-muted"></i>
      //             Delete
      //           </Dropdown.Item>
      //         </li>
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   ),
      // },
    ],
    [handleDeleteModal]
  );
  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          {company.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
          company.companyType === CompanyTypeEnum.SHIPMENT ? (
            <Link
              to="/waste-notification-add"
              className="btn btn-primary addMembers-modal"
            >
              <i className="las la-plus me-1"></i> Declare Waste
            </Link>
          ) : null}
        </Col>

        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                placeholder="Search for name or designation..."
                onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Print</Dropdown.Item>
                <Dropdown.Item>Export to Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <WasteNotificationWidget />

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {wasteNotifications && wasteNotifications.length > 0 ? (
                <TableContainer
                  onRowClick={onRowClick}
                  isPagination={true}
                  columns={columns}
                  data={wasteNotifications || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Delete */}

      <DeleteModal
        show={delet}
        handleClose={handleDeleteModal}
        deleteModalFunction={handleDeleteId}
      />
      <ToastContainer />

      <EditWasteNotification
        isShow={editWasteNotifications}
        handleClose={handleCloseEdit}
        edit={edit}
      />
      {/* <AddWasteNotificationInspections
        isShow={inspectionWasteNotifications}
        handleClose={handleCloseInspection}
        handleShow={handleInspectionWasteNotifications}
      /> */}
      {/* <AddWasteCollections
        isShow={collectionWasteNotifications}
        handleClose={handleCloseWasteCollection}
        handleShow={handleCollectionWasteNotifications}
      /> */}
      <WasteNotificationChatModal
        isShow={chatWasteNotifications}
        handleClose={handleCloseChat}
        handleShow={handleChatWasteNotifications}
      />
    </React.Fragment>
  );
};

export default WasteNotificationTable;
