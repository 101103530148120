import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row, Image, Button, Form } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

// import Images
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar8 from "../assets/images/users/avatar-8.jpg";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../helpers/url_helper";
import axios from "axios";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const WasteNotificationChat = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [state, setState] = useState<any[]>();
  const [message, setMessage] = useState("");
  // const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens, company } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/messages/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate, params.id, tokens]);

  const postMessage = async (e: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/messages/${params.id}`,
        { content: message },
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/messages/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      toast.success("Message Added Successfully", {
        autoClose: 2000,
      });
      setMessage("");
    } catch (error: any) {
      toast.error("Message Added Failed", {
        autoClose: 2000,
      });
      // console.error("error", error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  return (
    <Col>
      <Card>
        <Card.Header>
          <h4 className="card-title mb-0">Correspondence</h4>
        </Card.Header>

        <Card.Body>
          {/* <p className="text-muted">
            Use <code>data-simplebar-track="primary"</code> attribute to set
            primary color simplebar track.
          </p> */}

          <div className="mx-n3">
            <SimpleBar
              autoHide={false}
              data-simplebar-track="primary"
              style={{ maxHeight: "275px" }}
            >
              {/* <Link
                to="#!"
                className="text-reset notification-item d-block dropdown-item"
              >
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar-xs me-3">
                    <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                      <i className="ri-checkbox-circle-fill"></i>
                    </span>
                  </div>
                  <div className="flex-grow-1 text-muted">
                    <p className="m-0">
                      Your <span className="fw-bold text-dark">Elite</span>{" "}
                      author Graphic Optimization{" "}
                      <span className="text-secondary">reward</span> is ready!
                    </p>
                  </div>
                  <div className="flex-shrink-0 ms-2">
                    <div className="fs-xs text-muted">
                      <i className="mdi mdi-clock-outline"></i> 30 min ago
                    </div>
                  </div>
                </div>
              </Link> */}

              {state?.map((ms: any) => (
                <Link
                  to="#!"
                  className="text-reset notification-item d-block dropdown-item active"
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0 avatar-xs me-3">
                      {ms?.user?.photo ? (
                        <Image
                          src={ms?.user?.photo?.url || avatar2}
                          className="rounded-circle img-fluid"
                          alt="user-pic"
                        />
                      ) : (
                        <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                          <i className="ri-message-2-fill"></i>
                        </span>
                      )}
                    </div>
                    <div className="flex-grow-1 text-muted">
                      <h6 className="mb-1 fs-md">
                        {ms.createdBy}{" "}
                        <span className="text-secondary">
                          ({ms?.company?.companyType})
                        </span>
                      </h6>
                      <p className="mb-0 text-capitalize">
                        {ms.content}
                        {/* 🔔. */}
                      </p>
                    </div>

                    <div className="flex-shrink-0 ms-2">
                      <div className="fs-xs text-muted">
                        <i className="mdi mdi-clock-outline"></i>{" "}
                        {moment(ms?.createDateTime).fromNow()}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}

              {/* <div className="text-center my-2">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-loading mdi-spin fs-2xl align-middle me-2"></i>{" "}
                  Load more{" "}
                </Link>
              </div> */}
            </SimpleBar>
          </div>
          <Fragment>
            <div className="mb-3">
              {/* <Form.Label htmlFor="users">Name</Form.Label> */}
              <div className="form-icon right">
                <Form.Control
                  type="text"
                  as="textarea"
                  id="memberName"
                  name="name"
                  placeholder="Enter Your message"
                  value={message}
                  // value={formik.values.name || ""}
                  onChange={({ target: { value } }) => setMessage(value)}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // isInvalid={!!formik.errors.name}
                />
                <Col>
                  <i className="ri-mail-unread-line"></i>
                </Col>
              </div>
            </div>
            <div className="hstack gap-2 justify-content-end">
              <Button type="button" className="btn btn-light">
                Attachments
              </Button>
              <Button
                onClick={postMessage}
                type="submit"
                className="btn btn-success"
              >
                Send
              </Button>
            </div>
          </Fragment>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default WasteNotificationChat;
