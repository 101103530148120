import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  getOilPlatforms as getOilPlatformsApi,
  addOilPlatforms as addOilPlatformsApi,
  editOilPlatform as editOilPlatformApi,
  deleteOilPlatform as deleteOilPlatformApi,

  // Rig
  getRigs as getRigsApi,
  addRig as addRigsApi,
  editRig as editRigApi,
  deleteRig as deleteRigApi,
} from "../../helpers/fakebackend_helper";

export const getOilPlatforms = createAsyncThunk("platform/getOilPlatforms", async () => {
  try {
    const response = getOilPlatformsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addOilPlatform = createAsyncThunk(
  "platform/addOilPlatform",
  async (payload: any, { getState }) => {
    try {
      // const state = getState();
      // console.log('getState', state)
      const response = addOilPlatformsApi(payload);
      const data = await response;
      toast.success("OilPlatform added Successfully", { autoClose: 2000 });
      return data;
    } catch (error: any) {
      // console.log('error', error.message)
      // console.error('error', error?.message)
      toast.error("OilPlatform added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editOilPlatform = createAsyncThunk(
  "platform/editOilPlatform",
  async (payload: any) => {
    try {
      const response = editOilPlatformApi(payload, payload?.id);
      const data = await response;
      toast.success("OilPlatform edited Successfully", { autoClose: 2000 });
      // if(cb) cb();
      return data;
    } catch (error) {
      toast.error("OilPlatform edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteOilPlatform = createAsyncThunk(
  "platform/deleteOilPlatform",
  async (id: any) => {
    try {
      const response = deleteOilPlatformApi(id);
      toast.success("OilPlatform deleted Successfully", { autoClose: 1000 });
      return { id, ...response };
    } catch (error) {
      toast.error("OilPlatform deleted Failed", { autoClose: 1000 });
      return error;
    }
  }
);

// Rigs
export const getRigs = createAsyncThunk("platform/rigs", async () => {
  try {
    const response = getRigsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addRig = createAsyncThunk(
  "platform/addRig",
  async (user: any) => {
    try {
      const response = addRigsApi(user);
      const data = await response;
      toast.success("Rig added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Rig added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editRig = createAsyncThunk(
  "platform/editRig",
  async (payment: any) => {
    try {
      const response = editRigApi(payment);
      const data = await response;
      toast.success("Rig edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Rig edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteRig = createAsyncThunk(
  "platform/deleteRig",
  async (id: any) => {
    try {
      const response = deleteRigApi(id);
      toast.success("Rig deleted Successfully", { autoClose: 1000 });
      return { id, ...response };
    } catch (error) {
      toast.error("Rig deleted Failed", { autoClose: 1000 });
      return error;
    }
  }
);
