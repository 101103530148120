import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import logoDark from "../../../assets/images/logo-dark.png";
import moment from "moment";
import logoLight from "../../../assets/images/logo-light.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import WasteNotificationChat from "../../../Common/WasteNotificationChat";
import BillRecentActivities from "../../../Common/BillRecentActivities";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";
import MakePayments from "../../../Common/CrudModal/MakePayment";
import { PaymentStatusEnum } from "../../../helpers/enums/bill.enum";
import {
  InspectionStatusEnum,
  WasteCollectionStatusEnum,
} from "../../../helpers/enums/visitRequisition.enum";
const BillDetails = () => {
  document.title =
    "Waste Notification Details | Maritime Waste Management Admin & Dashboard Platform";
  const params = useParams();
  let navigate = useNavigate();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens, company } = useSelector(selectAccountAndLogin);

  const [state, setState] = useState<any>({});

  const [paymentBills, setPaymentBills] = useState<boolean>(false);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? child : null;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, params.id, tokens]);

  const handleClosePayment = () => setPaymentBills(false);

  const handlePaymentBills = () => {
    setPaymentBills(true);
  };

  const submitPayment = async (resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/payment/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/${params.id}`,
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setState(JSON.parse(JSON.stringify(response)));
      if (resetFunc) resetFunc();
      toast.success("Payment Successful", {
        autoClose: 2000,
      });
      handleClosePayment();
    } catch (error: any) {
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Bill Detail" title="Bill Details" />
          <Row className="justify-content-center">
            <Col xxl={9}>
              <Card id="demo">
                <Card.Body>
                  <Row className="p-4">
                    <Col lg={9}>
                      <h3 className="fw-bold mb-4">
                        Bill Number: {state.bill?.billNumber}
                      </h3>
                      <Row className="g-4">
                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Waste Notification No
                          </p>
                          <h5 className="fs-16 mb-0">
                            #VL<span id="invoice-no">25000355</span>
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Date
                          </p>
                          <h5 className="fs-16 mb-0">
                            <span id="invoice-date">23 Nov, 2021</span>{" "}
                            <small className="text-muted" id="invoice-time">
                              02:36PM
                            </small>
                          </h5>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Payment Status
                          </p>
                          <span
                            className={`badge ${
                              state.paymentStatus ===
                              PaymentStatusEnum.PaymentCompleted
                                ? "bg-success-subtle text-success"
                                : "bg-warning-subtle text-warning"
                            } fs-11`}
                            id="payment-status"
                          >
                            {state.paymentStatus}
                          </span>
                        </Col>

                        <Col lg={6} className="col-6">
                          <p className="text-muted mb-1 text-uppercase fw-medium fs-14">
                            Total Amount
                          </p>
                          <h5 className="fs-16 mb-0">
                            ₦
                            <span id="total-amount">
                              {(
                                state.bill?.totalTransactionValue || 0
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mt-sm-0 mt-3">
                        <div className="mb-4">
                          <img
                            src={logoDark}
                            className="card-logo card-logo-dark"
                            alt="logo dark"
                            height="17"
                          />
                          <img
                            src={logoLight}
                            className="card-logo card-logo-light"
                            alt="logo light"
                            height="17"
                          />
                        </div>
                        <h6 className="text-muted text-uppercase fw-semibold">
                          Address
                        </h6>
                        <p className="text-muted mb-1" id="address-details">
                          {state.company?.state}, {state.company?.country}
                        </p>
                        {/* <p className="text-muted mb-1" id="zip-code">
                          <span>Zip-code:</span> 90201
                        </p> */}
                        <h6>
                          <span className="text-muted fw-normal">Email:</span>
                          <span id="email">{state.company?.email}</span>
                        </h6>
                        {/* <h6>
                          <span className="text-muted fw-normal">Website:</span>{" "}
                          <a
                            href="https://themesbrand.com/"
                            className="link-primary"
                            target="_blank"
                            rel="noreferrer"
                            id="website"
                          >
                            www.themesbrand.com
                          </a>
                        </h6> */}
                        <h6 className="mb-0">
                          <span className="text-muted fw-normal">
                            Contact No:{" "}
                          </span>
                          <span id="contact-no"> +(01) 234 6789</span>
                        </h6>
                      </div>
                    </Col>
                  </Row>

                  <Row className="p-4 border-top border-top-dashed">
                    <Col lg={8}>
                      <Row className="g-3">
                        <div className="col-6">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Billing Address
                          </h6>
                          <p className="fw-medium mb-2" id="billing-name">
                            {state.user?.firstName} {state.user?.lastName}
                          </p>
                          <p
                            className="text-muted mb-1"
                            id="billing-address-line-1"
                          >
                            {state.user?.address}
                          </p>
                          <p className="text-muted mb-1">
                            <span>Phone: +</span>
                            <span id="billing-phone-no">(123) 456-7890</span>
                          </p>
                          <p className="text-muted mb-0">
                            <span>Tax: </span>
                            <span id="billing-tax-no">12-3456789</span>{" "}
                          </p>
                        </div>

                        {/* <div className="col-6">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Shipping Address
                          </h6>
                          <p className="fw-medium mb-2" id="shipping-name">
                            {state.user?.firstName} {state.user?.lastName}
                          </p>
                          <p
                            className="text-muted mb-1"
                            id="shipping-address-line-1"
                          >
                            {state.user?.address}
                          </p>
                          <p className="text-muted mb-1">
                            <span>Phone: +</span>
                            <span id="shipping-phone-no">(123) 456-7890</span>
                          </p>
                        </div> */}
                      </Row>
                    </Col>

                    <Col lg={4}>
                      <h6 className="text-muted text-uppercase fw-semibold mb-3">
                        Total Amount
                      </h6>
                      <h3 className="fw-bold mb-2">
                        ₦
                        {(
                          +state.bill?.totalTransactionValue || 0
                        ).toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        })}
                      </h3>
                      {state?.bill?.status !==
                      PaymentStatusEnum.PaymentCompleted ? (
                        <span
                          className={`
                        badge 
                        ${
                          moment(state.collectionScheduledDate)
                            .add(7, "days")
                            .isAfter(moment())
                            ? "bg-danger-subtle text-danger"
                            : "bg-success-subtle text-success"
                        } fs-12
                        `}
                        >
                          Due Date:{" "}
                          {state?.collectionScheduledDate
                            ? moment(state.collectionScheduledDate)
                                .add(7, "days")
                                .format("MMM Do YYYY")
                            : "23 April, 2024"}
                        </span>
                      ) : null}
                    </Col>
                  </Row>

                  <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                    {/* <Row> */}

                    {/* SHIPMENT */}
                    {company.companyType === CompanyTypeEnum.SHIPMENT &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted ? (
                      <button
                        onClick={handlePaymentBills}
                        className="btn btn-primary"
                      >
                        Pay Now
                      </button>
                    ) : null}

                    {/* OIL AND GAS PLATFORM */}
                    {company.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionCompleted &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted ? (
                      <button
                        onClick={handlePaymentBills}
                        className="btn btn-primary"
                      >
                        Pay Now
                      </button>
                    ) : null}
                    {/* Confirmed Quantity by Collector */}

                    {/* {(company.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <button className="btn btn-info">
                        Confirm Collection Quantity
                      </button>
                    ) : null} */}
                    {/* Confirmed Quantity by Requestor */}

                    {/* {(company.companyType ===
                      CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
                      company.companyType ===
                        CompanyTypeEnum.PORT_RECEPTION_FACILITIES) &&
                    state.paymentStatus !==
                      PaymentStatusEnum.PaymentCompleted &&
                    state.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <button className="btn btn-info">
                        Initiate Collection Confirmation
                      </button>
                    ) : null} */}

                    {/* PORT_RECEPTION_FACILITIES / WASTE_MANAGEMENT_PROCESSING */}
                    {(company.companyType ===
                      CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
                      company.companyType ===
                        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING) &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.CollectionScheduled ? (
                      <Link
                        to={`/waste-notification-details/${state.id}/edit`}
                        className="btn btn-info"
                      >
                        View Waste Notification
                      </Link>
                    ) : null}

                    {/* SHIPMENT / OIL AND GAS PLATFORM */}
                    {(company.companyType ===
                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
                      company.companyType === CompanyTypeEnum.SHIPMENT) &&
                    state.wasteCollectionStatus ===
                      WasteCollectionStatusEnum.AwaitingCollection ? (
                      <Link
                        to={`/waste-notification-details/${state.id}/edit`}
                        className="btn btn-info"
                      >
                        View Waste Notification
                      </Link>
                    ) : null}
                    {/* </Row> */}
                  </div>
                  <Row>
                    <Col lg={12}>
                      <Card.Body className="card-body p-4">
                        <div className="table-responsive">
                          <Table className="table-borderless text-center table-nowrap align-middle mb-0">
                            <thead>
                              <tr className="table-active">
                                <th scope="col" style={{ width: "50px" }}>
                                  #
                                </th>
                                <th scope="col">Waste Details</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Quantity</th>
                                <th scope="col" className="text-end">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody id="products-list">
                              {state.wasteDeclarations &&
                                state.wasteDeclarations.map(
                                  (wd: any, index: number) => (
                                    <tr key={wd.id}>
                                      <th scope="row">{index + 1}</th>
                                      <td className="text-start">
                                        <span className="fw-medium">
                                          {wd.wasteType}
                                        </span>
                                        <p className="text-muted mb-0">
                                          {wd.name}
                                        </p>
                                      </td>
                                      <td>
                                        ₦
                                        {(+wd.rate || +"239.98").toLocaleString(
                                          navigator.language,
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                      </td>
                                      <td>{wd.quantity}</td>
                                      <td className="text-end">
                                        ₦
                                        {(
                                          +wd.quantity * (+wd?.rate || 239.98)
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="border-top border-top-dashed mt-2">
                          <Table
                            className="table-borderless table-nowrap align-middle mb-0 ms-auto"
                            style={{ width: "250px" }}
                          >
                            <tbody>
                              <tr>
                                <td>Sub Total</td>
                                <td className="text-end">
                                  ₦
                                  {(
                                    +state.bill?.totalTransactionValue ||
                                    0 - +state.bill?.totalTax ||
                                    0 - +state.bill?.discount ||
                                    0
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                              <tr>
                                <td>Estimated Tax (7.5%)</td>
                                <td className="text-end">
                                  ₦
                                  {(+state.bill?.totalTax || 0).toLocaleString(
                                    navigator.language,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Discount{" "}
                                  <small className="text-muted">
                                    (Mwp.ng24)
                                  </small>
                                </td>
                                <td className="text-end">
                                  - ₦
                                  {(+state.bill?.discount || 0).toLocaleString(
                                    navigator.language,
                                    { minimumFractionDigits: 2 }
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping Charge</td>
                                <td className="text-end">₦65.00</td>
                              </tr>
                              <tr className="border-top border-top-dashed fs-15">
                                <th scope="row">Total Amount</th>
                                <th className="text-end">
                                  ₦
                                  {(
                                    +state.bill?.totalTransactionValue || 0
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {/* <div className="mt-3">
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                            Payment Details:
                          </h6>
                          <p className="text-muted mb-1">
                            Payment Method:{" "}
                            <span className="fw-medium" id="payment-method">
                              Mastercard
                            </span>
                          </p>
                          <p className="text-muted mb-1">
                            Card Holder:{" "}
                            <span className="fw-medium" id="card-holder-name">
                              David Nichols
                            </span>
                          </p>
                          <p className="text-muted mb-1">
                            Card Number:{" "}
                            <span className="fw-medium" id="card-number">
                              xxx xxxx xxxx 1234
                            </span>
                          </p>
                          <p className="text-muted">
                            Total Amount:{" "}
                            <span className="fw-medium" id="">
                              ₦{" "}
                            </span>
                            <span id="card-total-amount">
                              {(
                                state.bill?.totalTransactionValue || 0
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </p>
                        </div> */}
                        {addLayoutData(
                          [
                            CompanyTypeEnum.SHIPMENT,
                            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                          ],
                          <div className="mt-4">
                            <div className="alert alert-info">
                              <p className="mb-0">
                                <span className="fw-semibold">NOTES:</span>
                                <span id="note">
                                  All bills are to be paid within 7 days from
                                  receipt of invoice. To be paid by cheque or
                                  credit card or direct payment online. If
                                  account is not paid within 7 days the credits
                                  details supplied as confirmation of work
                                  undertaken will be charged the agreed quoted
                                  fee noted above.
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                          <Link to="#" className="btn btn-info">
                            <i className="ri-printer-line align-bottom me-1"></i>{" "}
                            Print
                          </Link>
                          <Link to="#" className="btn btn-primary">
                            <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                            Download
                          </Link>
                        </div>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <BillRecentActivities state={state} />
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>

      <MakePayments
        amountDue={state?.bill?.amountDue}
        isShow={paymentBills}
        handlePayment={submitPayment}
        handleClose={handleClosePayment}
        handleShow={handlePaymentBills}
      />
    </React.Fragment>
  );
};

export default BillDetails;
