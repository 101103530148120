import React, { Fragment } from "react";
import { Card, Col, Row, Image, Modal, Form, Button } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

// import Images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";

interface WasteNotificationChatModalProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
}

const WasteNotificationChatModal = ({
  isShow,
  handleClose,
  handleShow,
}: WasteNotificationChatModalProps) => {
  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Waste Collections Form</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            {/* <Col xl={4} lg={6}> */}
            <Card>
              <Card.Header>
                <h4 className="card-title mb-0">Correspondence</h4>
              </Card.Header>

              <Card.Body>
                {/* <p className="text-muted">
                    Use <code>data-simplebar-track="primary"</code> attribute to
                    set primary color simplebar track.
                  </p> */}

                <div className="mx-n3">
                  <SimpleBar
                    autoHide={false}
                    data-simplebar-track="primary"
                    style={{ maxHeight: "275px" }}
                  >
                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                            <i className="ri-checkbox-circle-fill"></i>
                          </span>
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <p className="m-0">
                            Your{" "}
                            <span className="fw-bold text-dark">Elite</span>{" "}
                            author Graphic Optimization{" "}
                            <span className="text-secondary">reward</span> is
                            ready!
                          </p>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i> 30 min ago
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item active"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <Image
                            src={avatar2}
                            className="rounded-circle img-fluid"
                            alt="user-pic"
                          />
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <h6 className="mb-1 fs-md">Angela Bernier</h6>
                          <p className="mb-0">
                            Answered to your comment on the cash flow forecast's
                            graph 🔔.
                          </p>
                        </div>

                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i> 01 hr ago
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-lg">
                            <i className="ri-message-2-fill"></i>
                          </span>
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <p className="m-0">
                            You have received{" "}
                            <span className="fw-bold text-success">20</span> new
                            messages in the conversation
                          </p>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i> 02 hrs ago
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <Image
                            src={avatar8}
                            className="rounded-circle img-fluid"
                            alt="user-pic"
                          />
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <h6 className="mb-1 fs-md">Maureen Gibson</h6>
                          <p className="mb-0">
                            We talked about a project on linkedin.
                          </p>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i> 02 hrs ago
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to="#!"
                      className="text-reset notification-item d-block dropdown-item"
                    >
                      <div className="d-flex">
                        <div className="flex-shrink-0 avatar-xs me-3">
                          <span className="avatar-title bg-info-subtle text-info rounded-circle fs-lg">
                            <i className="ri-checkbox-circle-fill"></i>
                          </span>
                        </div>
                        <div className="flex-grow-1 text-muted">
                          <p className="m-0">
                            Your{" "}
                            <span className="fw-bold text-dark">Elite</span>{" "}
                            author Graphic Optimization{" "}
                            <span className="text-secondary">reward</span> is
                            ready!
                          </p>
                        </div>
                        <div className="flex-shrink-0 ms-2">
                          <div className="fs-xs text-muted">
                            <i className="mdi mdi-clock-outline"></i> 30 min ago
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="text-center my-2">
                      <Link to="#" className="text-success">
                        <i className="mdi mdi-loading mdi-spin fs-2xl align-middle me-2"></i>{" "}
                        Load more{" "}
                      </Link>
                    </div>
                  </SimpleBar>
                </div>
                <Fragment>
                  <div className="mb-3">
                    {/* <Form.Label htmlFor="users">Name</Form.Label> */}
                    <div className="form-icon right">
                      <Form.Control
                        type="text"
                        as="textarea"
                        id="memberName"
                        name="name"
                        placeholder="Enter Your message"
                        // value={formik.values.name || ""}
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        // isInvalid={!!formik.errors.name}
                      />
                      <Col>
                        <i className="ri-mail-unread-line"></i>
                      </Col>
                    </div>
                  </div>
                  <div className="hstack gap-2 justify-content-end">
                    <Button type="button" className="btn btn-light">
                      Attachments
                    </Button>
                    <Button type="submit" className="btn btn-success">
                      Send
                    </Button>
                  </div>
                </Fragment>
              </Card.Body>
            </Card>
            {/* </Col> */}
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default WasteNotificationChatModal;
