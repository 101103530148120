import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Card, Col, Dropdown, Form, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import TableContainer from "../../../Common/Tabledata/TableContainer";
import { handleSearchData } from "../../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../../Common/Tabledata/NoSearchResult";
import axios from "axios";
import { PaymentStatusEnum } from "../../../helpers/enums/bill.enum";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_BILLS } from "../../../helpers/url_helper";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";

interface billProps {}

const BillTable = ({}: billProps) => {
  const [billList, setBillList] = useState<any>([]);
  const [bills, setBills] = useState<any>([]);
  const [billStatus, setBillStatus] = useState<any>({});

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectBillsList = createSelector(
    (state: any) => state.Login,
    (login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );

  const { tokens, company } = useSelector(selectBillsList);

  const onRowClick = (cell: any) => {
    console.log("cell", cell);
    navigate(`/bill-details/${cell.row.original?.visitationRequisition?.id}`);
  };

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? [child] : [];
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_BILLS}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setBillList(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate, tokens]);

  useEffect(() => {
    setBills(billList);
  }, [billList]);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${GET_BILLS}/bill-status`
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setBillStatus(JSON.parse(JSON.stringify(response)));
    };

    getData();
  }, []);

  const toggleTab = (type: any) => {
    if (type !== "all") {
      setBills(billList.filter((bill: any) => bill.status === type));
    } else {
      setBills(billList);
    }
  };

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value;

    if (item === "All Tasks") {
      setBills([...billList]);
    } else {
      handleSearchData({ data: billList, item: item, setState: setBills });
    }
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: "BILL NUMBER",
        accessor: "billNumber",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "DATE",
        accessor: "createDateTime",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) =>
          cell.row.original.createDateTime
            ? new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(cell.row.original.createDateTime))
            : "",
      },

      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
          CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
          CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        ],
        {
          Header: "PLATFORM",
          accessor: "visitationRequisition.oilPlatform.name",
          Filter: false,
          isSortable: true,
        }
      ),
      ...addLayoutData(
        [
          CompanyTypeEnum.PLATFORM_ADMIN,
          CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
          CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
          CompanyTypeEnum.SHIPMENT,
        ],
        {
          Header: "SHIP",
          accessor: "visitationRequisition.ship.name",
          Filter: false,
          isSortable: true,
        }
      ),
      {
        Header: "AMOUNT DUE (NGN)",
        accessor: "amountDue",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => (
          <>
            {(cell.row.original.status === PaymentStatusEnum.PaymentCompleted
              ? 0
              : +cell.row.original.amountDue
            ).toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}
          </>
        ),
      },
      {
        Header: "AMOUNT PAID (NGN)",
        accessor: "amountPaid",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => (
          <>
            {(cell.row.original.status === PaymentStatusEnum.PaymentCompleted
              ? +cell.row.original.amountPaid || +cell.row.original.amountDue
              : 0
            ).toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })}
          </>
        ),
      },
      // {
      //   Header: "AMOUNT",
      //   accessor: "amount",
      //   Filter: false,
      //   isSortable: true,
      //   Cell: (cell: any) => <>${cell.row.original.amount}</>,
      // },
      {
        Header: "Status",
        accessor: "status",
        key: "status",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          switch (cell.row.original.status) {
            case PaymentStatusEnum.PaymentCompleted:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.status}
                </span>
              );

            case PaymentStatusEnum.AwaitingPayment:
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.status}
                </span>
              );
            // case PaymentStatusEnum.Suspended:
            // case PaymentStatusEnum.Deleted:
            //   return (
            //     <span className="badge bg-danger-subtle text-danger p-2">
            //       {cell.row.original.status}
            //     </span>
            //   );
          }
        },
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      //   Filter: false,
      //   style: { width: "12%" },

      //   isSortable: false,
      //   Cell: (cell: any) => (
      //     <Dropdown>
      //       <Dropdown.Toggle
      //         as="button"
      //         className="btn btn-soft-secondary btn-sm arrow-none"
      //       >
      //         <i className="las la-ellipsis-h align-middle fs-18"></i>
      //       </Dropdown.Toggle>
      //       <Dropdown.Menu className="dropdown-menu-end">
      //         <li>
      //           <Dropdown.Item to="#">
      //             <i className="las la-eye fs-18 align-middle me-2 text-muted"></i>
      //             View
      //           </Dropdown.Item>
      //         </li>
      //         <li>
      //           <Dropdown.Item to="#">
      //             <i className="las la-file-download fs-18 align-middle me-2 text-muted"></i>
      //             Download
      //           </Dropdown.Item>
      //         </li>
      //       </Dropdown.Menu>
      //     </Dropdown>
      //   ),
      // },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Tab.Container defaultActiveKey="all">
                <Nav
                  as="ul"
                  variant="tabs"
                  className="nav-tabs nav-tabs-custom nav-success mb-3"
                >
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="all"
                      onClick={() => {
                        toggleTab("all");
                      }}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>
                  {billStatus &&
                    Object.keys(billStatus).map((sh) => (
                      <Nav.Item as="li" key={sh}>
                        <Nav.Link
                          eventKey={billStatus[sh]}
                          onClick={() => {
                            toggleTab(billStatus[sh]);
                          }}
                        >
                          {billStatus[sh]}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>

                <Card>
                  <Card.Body>
                    {bills && bills.length > 0 ? (
                      <TableContainer
                        onRowClick={onRowClick}
                        isPagination={true}
                        columns={columns}
                        data={bills || []}
                        customPageSize={9}
                        divClassName="table-card table-responsive"
                        tableClass="table-hover table-nowrap align-middle mb-0"
                        isBordered={false}
                        theadClass="table-light"
                        PaginationClass="align-items-center mt-4 gy-3"
                      />
                    ) : (
                      <NoSearchResult />
                    )}
                  </Card.Body>
                </Card>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  );
};

export default BillTable;
