import React from "react";
import { Card, Col, Dropdown } from "react-bootstrap";
import { StructureData } from "./StructureData";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";

const Structure = () => {
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { company } = useSelector(selectAccountAndLogin);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <Card.Body>
            <div className="d-flex align-items-start">
              <div className="flex-grow-1 overflow-hidden">
                <h5 className="card-title mb-2 text-truncate">Structure</h5>
              </div>
              <div className="flex-shrink-0 ms-2">
                <Dropdown>
                  <Dropdown.Toggle as="a" className="text-reset arrow-none">
                    <span className="fw-semibold text-uppercase fs-14">
                      Sort By:
                    </span>{" "}
                    <span className="text-muted">
                      Weekly<i className="las la-angle-down fs-12 ms-2"></i>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end">
                    <Dropdown.Item>Yearly</Dropdown.Item>
                    <Dropdown.Item>Monthly</Dropdown.Item>
                    <Dropdown.Item>Weekly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <StructureData
              dataColors='["--in-primary", "--in-primary-rgb, 0.7", "--in-light"]'
              className="apex-charts"
            />

            <div className="px-2">
              <div className="structure-list d-flex justify-content-between border-bottom">
                <p className="mb-0">
                  <i className="las la-dot-circle fs-18 text-primary me-2"></i>
                  Bills
                </p>
                <div>
                  <span className="pe-2 pe-sm-5">
                    ₦
                    {company.companyType ===
                    CompanyTypeEnum.PORT_RECEPTION_FACILITIES
                      ? "2,403,590.00"
                      : company.companyType ===
                        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING
                      ? "1,403,590.00"
                      : company.companyType ===
                        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY
                      ? "1,003,590.00"
                      : company.companyType ===
                        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY
                      ? "2,003,590.00"
                      : company.companyType ===
                        CompanyTypeEnum.OIL_AND_GAS_PLATFORM
                      ? "4,403,590.00"
                      : "3,403,590.00"}
                  </span>
                  <span className="badge bg-primary"> + 0.2% </span>
                </div>
              </div>
              <div className="structure-list d-flex justify-content-between border-bottom">
                <p className="mb-0">
                  <i className="las la-dot-circle fs-18 text-primary me-2"></i>
                  {company.companyType ===
                    CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
                  company.companyType ===
                    CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
                  company.companyType ===
                    CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY ||
                  company.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY
                    ? /* "Collected" */ "Paid Bills"
                    : "Paid Bills"}
                </p>
                <div>
                  <span className="pe-2 pe-sm-5">₦1,403,590.00</span>
                  <span className="badge bg-primary"> - 0.7% </span>
                </div>
              </div>
              <div className="structure-list d-flex justify-content-between pb-0">
                <p className="mb-0">
                  <i className="las la-dot-circle fs-18 text-primary me-2"></i>

                  {company.companyType ===
                    CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
                  company.companyType ===
                    CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
                  company.companyType ===
                    CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY ||
                  company.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY
                    ? "Awaiting Payment"
                    : "Outstanding Bills"}
                </p>
                <div>
                  <span className="pe-2 pe-sm-5">₦1,000,000.00</span>
                  <span className="badge bg-primary"> + 0.4% </span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Structure;
