import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  getWalletMetrics as getWalletMetricsApi,
  getWalletHistories as getWalletHistoriesApi,
  getWallets as getWalletsApi,
  addWallets as addWalletsApi,
  editWallet as editWalletApi,
  deleteWallet as deleteWalletApi,
} from "../../helpers/fakebackend_helper";

export const getWallets = createAsyncThunk("invoice/getWallets", async () => {
  try {
    const response = getWalletsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const getWalletHistories = createAsyncThunk("invoice/getWalletHistories", async () => {
  try {
    const response = getWalletHistoriesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const getWalletMetrics = createAsyncThunk("invoice/getWalletMetrics", async () => {
  try {
    const response = getWalletMetricsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addWallet = createAsyncThunk(
  "invoice/addWallet",
  async (user: any, { getState }) => {
    try {
      // const state = getState();
      // console.log('addWallet getState', state)
      const response = addWalletsApi(user);
      const data = await response;
      toast.success("Wallet added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Wallet added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editWallet = createAsyncThunk(
  "invoice/editWallet",
  async (payment: any) => {
    try {
      const response = editWalletApi(payment);
      const data = await response;
      toast.success("Wallet edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Wallet edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteWallet = createAsyncThunk(
  "invoice/deleteWallet",
  async (id: any) => {
    try {
      const response = deleteWalletApi(id);
      toast.success("Wallet deleted Successfully", { autoClose: 1000 });
      return { id, ...response };
    } catch (error) {
      toast.error("Wallet deleted Failed", { autoClose: 1000 });
      return error;
    }
  }
);
