import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link, matchPath, useLocation } from "react-router-dom";

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar8 from "../assets/images/users/avatar-8.jpg";

const WalletBalance = ({ walletBalance }: any) => {
  const { pathname } = useLocation();

  return matchPath("/dashboard", pathname) ? (
    <React.Fragment>
      <div
        className="d-none d-xl-inline-block fw-medium user-name-text fs-16 text-capitalize"
        // className="dropdown topbar-head-dropdown ms-1 header-item"
        id="notificationDropdown"
      >
        {/* <i className="las la-wallet fs-24"></i> */}
        <i className="las la-wallet fs-16"></i>
        <span>
          {/* <span className="badge bg-success-subtle text-success"> */}
          <span
            style={{ marginLeft: "5px" }}
            className="badge bg-success-subtle text-success"
          >
            ₦
            {Number(walletBalance?.amount || 0).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 2,
              }
            )}
          </span>
        </span>
      </div>
    </React.Fragment>
  ) : null;
};

export default WalletBalance;
