import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  getCompanies as getCompaniesApi,
  addCompanies as addCompaniesApi,
  editCompany as editCompanyApi,
  activateCompany as activateCompanyApi,
  deactivateCompany as deactivateCompanyApi,
  deleteCompany as deleteCompanyApi,
} from "../../helpers/fakebackend_helper";

export const getCompanies = createAsyncThunk("invoice/getCompanies", async () => {
  try {
    const response = getCompaniesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addCompany = createAsyncThunk(
  "invoice/addCompany",
  async (user: any, { getState }) => {
    try {
      // const state = getState();
      // console.log('addCompany getState', state)
      const response = addCompaniesApi(user);
      const data = await response;
      toast.success("Company added Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Company added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const editCompany = createAsyncThunk(
  "invoice/editCompany",
  async (payment: any) => {
    try {
      const response = editCompanyApi(payment);
      const data = await response;
      toast.success("Company edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Company edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const activateCompany = createAsyncThunk(
  "invoice/activateCompany",
  async (company: any) => {
    try {
      const response = activateCompanyApi(company);
      const data = await response;
      toast.success("Company edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Company edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deactivateCompany = createAsyncThunk(
  "invoice/deactivateCompany",
  async (company: any) => {
    try {
      const response = deactivateCompanyApi(company);
      const data = await response;
      toast.success("Company edited Successfully", { autoClose: 2000 });
      return data;
    } catch (error) {
      toast.error("Company edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "invoice/deleteCompany",
  async (id: any) => {
    try {
      const response = deleteCompanyApi(id);
      toast.success("Company deleted Successfully", { autoClose: 1000 });
      return { id, ...response };
    } catch (error) {
      toast.error("Company deleted Failed", { autoClose: 1000 });
      return error;
    }
  }
);
