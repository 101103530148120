import React, { useState } from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Container } from "react-bootstrap";
import OilPlatformTable from "./OilPlatformTable";

const OilPlatforms = () => {
  document.title = "OilPlatforms | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hideOilPlatformModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Oil Platforms" title="Oil Platforms" />
          <OilPlatformTable isShow={isShow} hideOilPlatformModal={hideOilPlatformModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OilPlatforms;
