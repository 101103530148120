import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  addClientWasteNotifications as addClientWasteNotificationsApi,
  getClientWasteNotifications as getClientWasteNotificationsApi,
  editClientWasteNotifications as editClientWasteNotificationsApi,
  deleteClientWasteNotifications as deleteClientWasteNotificationsApi,
} from "../../helpers/fakebackend_helper";

export const addClientWasteNotifications = createAsyncThunk(
  "wasteNotification/addPortOfOperations",
  async ({
    wasteNotification,
    navigate,
    toasterSuccess,
    toasterFailed
  }: {
    wasteNotification: any;
    navigate: any;
    toasterSuccess: any,
    toasterFailed: any
  }) => {
    try {
      const response = addClientWasteNotificationsApi(wasteNotification);
      const data = await response;
      console.log("wasteNotification - data", data);
      if (toasterSuccess) toasterSuccess()
      const timeout = setTimeout(() => {
        if (navigate) navigate();
        clearTimeout(timeout);
      }, 2000);
      // if (
      //   data?.statusCode &&
      //   (data.statusCode !== 200 || data?.statusCode !== 201)
      // ) {
      //   toast.error("Waste Notification Edited Failed", { autoClose: 2000 });
      //   throw data;
      // } else {
      //   toast.success("Waste Notification Added Successfully", {
      //     autoClose: 2000,
      //   });
      //   return data;
      // }
      return data.data;
    } catch (error) {
      toast.error("Waste Notification Added Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const getClientWasteNotifications = createAsyncThunk(
  "wasteNotification/getClientWasteNotifications",
  async () => {
    try {
      const response = getClientWasteNotificationsApi();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const editClientWasteNotifications = createAsyncThunk(
  "wasteNotification/editClientWasteNotifications",
  async (wasteNotification: any) => {
    try {
      const response = editClientWasteNotificationsApi(wasteNotification, wasteNotification?.id);
      const data = await response;
      toast.success("Waste Notification edited Successfully", {
        autoClose: 2000,
      });
      const origin = window.location.origin
      window.location.replace(`${origin}/waste-notification-details/${wasteNotification.id}`);
      return data;
    } catch (error) {
      toast.error("Waste Notification edited Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const deleteClientWasteNotifications = createAsyncThunk(
  "wasteNotification/deleteClientWasteNotifications",
  async (id: any) => {
    try {
      const response = deleteClientWasteNotificationsApi(id);
      toast.success("Waste Notification Deleted Successfully", {
        autoClose: 2000,
      });
      return { id, ...response };
    } catch (error) {
      toast.error("Waste Notification Deleted Failed", { autoClose: 2000 });
      return error;
    }
  }
);
