import React from "react";
import BreadCrumb from "../../Common/BreadCrumb";
import { Container } from "react-bootstrap";
import WalletsTable from "./WalletsTable";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";

const Wallets = () => {
  document.title =
    "Waste Notification | Maritime Waste Management Admin & Dashboard Platform";

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      user: login.user,
    })
  );
  const { user } = useSelector(selectAccountAndLogin);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(user?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? child : "Waste Collections";
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitle={addLayoutData(
              [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
              "Wallets"
            )}
            title={addLayoutData(
              [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
              "Wallets"
            )}
          />
          <WalletsTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Wallets;
