import { Navigate } from "react-router-dom";

// Dashboard

import Dashboard from "../pages/Dashboard/Index";
import Signin from "../pages/AuthenticationInner/Signin";
import Signup from "../pages/AuthenticationInner/Signup";
import PasswordReset from "../pages/AuthenticationInner/PasswordReset";
import Lockscreen from "../pages/AuthenticationInner/Lockscreen";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";
import Users from "../pages/Users";
import Ships from "../pages/Ships";
import Companies from "../pages/Companies";
import OilPlatforms from "../pages/OilPlatformManagement/OilPlatform";
import PortReceptions from "../pages/Facilities/PortReception";
import WasteManagements from "../pages/Facilities/WasteManagement";
import PortOfOperations from "../pages/Facilities/PortOfOperation";
import Rigs from "../pages/OilPlatformManagement/Rigs";
import WasteNotification from "../pages/WasteNotificationManagement/WasteNotification";
import AddWasteNotification from "../pages/WasteNotificationManagement/AddWasteNotification";
import WasteNotificationDetails from "../pages/WasteNotificationManagement/WasteNotificationDetails";
import EditWasteNotification from "../pages/WasteNotificationManagement/EditWasteNotification";
import Bills from "../pages/BillManagement/Bills";
import BillDetails from "../pages/BillManagement/BillDetails";
import ComplianceInspectionReport from "../pages/WasteNotificationManagement/InspectionReport";
import Wallets from "../pages/Wallets";
import WasteComplianceCertificate from "../pages/WasteNotificationManagement/WasteComplianceCertificate";


interface RouteObject {
  path: string;
  component: any;
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/index", component: <Dashboard /> },
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },

  { path: "/bill", component: <Bills /> },
  { path: "/bill-details/:id", component: <BillDetails /> },
  { path: "/waste-notification", component: <WasteNotification /> },
  { path: "/waste-notification-add", component: <AddWasteNotification /> },
  { path: "/waste-notification-details/:id/edit", component: <EditWasteNotification /> },
  { path: "/waste-notification-details/:id", component: <WasteNotificationDetails /> },

  { path: "/user", component: <Users /> },
  { path: "/ships", component: <Ships /> },
  { path: "/companies", component: <Companies /> },
  { path: "/oil-platforms", component: <OilPlatforms /> },
  { path: "/rigs", component: <Rigs /> },
  { path: "/port-receptions", component: <PortReceptions /> },
  { path: "/port-of-operations", component: <PortOfOperations /> },
  { path: "/waste-managements", component: <WasteManagements /> },
  { path: "/wallets", component: <Wallets /> },
  { path: "/compliance-certificate/:id", component: <WasteComplianceCertificate /> },
  { path: "/compliance-inspection-report/:id", component: <ComplianceInspectionReport /> },

   //  Profile
   { path: "/user-profile", component: <UserProfile/>},

];

const publicRoutes: Array<RouteObject> = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <Register /> },
  
  { path: "/auth-signin",  component: <Signin /> },
  { path: "/auth-signup",  component: <Signup /> },
  { path: "/auth-pass-reset",  component: <PasswordReset /> },
  { path: "/auth-lockscreen",  component: <Lockscreen /> },

];

export { authProtectedRoutes, publicRoutes}