import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { editShip as onEditShip } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";

interface shipeditProps {
  isShow: any;
  handleClose: any;
  edit: any;
  // isEdit: any
}

const EditShip = ({ isShow, handleClose, edit }: shipeditProps) => {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (edit && edit.id) || "",
      name: (edit && edit.name) || "",
      imoNumber: (edit && edit.imoNumber) || "",
      mmsiNumber: (edit && edit.mmsiNumber) || "",
      shipType: (edit && edit.shipType) || "",
      flag: (edit && edit.flag) || "",
      grossTonnage: (edit && edit.grossTonnage) || "",
      // status: (edit && edit.status) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      imoNumber: Yup.string().required("Please imo Number"),
      mmsiNumber: Yup.string().required("Please Enter ship mmsi number"),
      shipType: Yup.string().required("Please choose Your Ship type"),
      flag: Yup.string().required("Please Enter flag"),
      grossTonnage: Yup.string().required("Please Enter gross tonnage"),
      // status: Yup.string().required("Please choose Your status"),
    }),

    onSubmit: (values: any) => {
      const UpdateShip = {
        id: values.id,
        name: values.name,
        imoNumber: values.imoNumber,
        mmsiNumber: values.mmsiNumber,
        shipType: values.shipType,
        flag: values.flag,
        grossTonnage: values.grossTonnage,
        // status: values.status,
      };

      dispatch(onEditShip(UpdateShip));
      formik.resetForm();
    },
  });
  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Edit Ship Details</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Ship Name
                </label>
                <Form.Control
                  type="name"
                  name="name"
                  className="form-control bg-light border-light"
                  placeholder="Enter Ship Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  isInvalid={
                    formik.touched.name && formik.errors.name
                      ? true
                      : false
                  }
                />
                {formik.touched.name && formik.errors.name ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Row>
                  <Col>
                    <label htmlFor="imoNumber" className="form-label">
                      Imo Number
                    </label>
                    <Form.Control
                      type="text"
                      name="imoNumber"
                      className="form-control bg-light border-light"
                      placeholder="Enter Imo Number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.imoNumber || ""}
                      isInvalid={
                        formik.touched.imoNumber && formik.errors.imoNumber
                          ? true
                          : false
                      }
                    />
                    {formik.touched.imoNumber && formik.errors.imoNumber ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.imoNumber}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col>
                    <label htmlFor="mmsiNumber" className="form-label">
                      MMSI Number
                    </label>
                    <Form.Control
                      type="text"
                      name="mmsiNumber"
                      className="form-control bg-light border-light"
                      placeholder="Enter MMSI Number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mmsiNumber || ""}
                      isInvalid={
                        formik.touched.mmsiNumber && formik.errors.mmsiNumber
                          ? true
                          : false
                      }
                    />
                    {formik.touched.mmsiNumber && formik.errors.mmsiNumber ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.mmsiNumber}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                </Row>
              </div>

              <div className="mb-3">
                <label htmlFor="flag" className="form-label">
                  Flag Bearing
                </label>
                <Form.Control
                  type="text"
                  name="flag"
                  className="form-control bg-light border-light"
                  placeholder="Enter Flag Bearing"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.flag || ""}
                  isInvalid={
                    formik.touched.flag && formik.errors.flag ? true : false
                  }
                />
                {formik.touched.flag && formik.errors.flag ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.flag}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Row>
                  <Col>
                    <label htmlFor="grossTonnage" className="form-label">
                      Gross Tonnage
                    </label>
                    <Form.Control
                      type="number"
                      name="grossTonnage"
                      className="form-control bg-light border-light"
                      placeholder="Enter grossTonnage"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.grossTonnage || ""}
                      isInvalid={
                        formik.touched.grossTonnage &&
                        formik.errors.grossTonnage
                          ? true
                          : false
                      }
                    />
                    {formik.touched.grossTonnage &&
                    formik.errors.grossTonnage ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.grossTonnage}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                  <Col>
                    <label htmlFor="shipType" className="form-label">
                      Ship Type
                    </label>
                    <Form.Control
                      type="text"
                      name="shipType"
                      className="form-control bg-light border-light"
                      placeholder="Enter shipType"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.shipType || ""}
                      isInvalid={
                        formik.touched.shipType && formik.errors.shipType
                          ? true
                          : false
                      }
                    />
                    {formik.touched.shipType && formik.errors.shipType ? (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.shipType}
                      </Form.Control.Feedback>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={handleClose}
                >
                  Edit Ship
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditShip;
