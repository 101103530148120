import { createSlice } from "@reduxjs/toolkit";

import {
  getClientWasteNotifications,
  editClientWasteNotifications,
  deleteClientWasteNotifications,
  addClientWasteNotifications
} from "./thunk";
// import { clientWasteNotificationList } from "../../Common/data";

export const initialState = {
  // clientWasteNotificationsList: clientWasteNotificationList || [],
  clientWasteNotificationsList: [],
  error: {},
  wasteNotification: {},
};

const WasteNotificationSlice: any = createSlice({
  name: "WasteNotification",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    //WasteNotification Transaction
    builder.addCase(
      getClientWasteNotifications.fulfilled,
      (state: any, action: any) => {
        state.clientWasteNotificationsList = action.payload;
      }
    );
    builder.addCase(
      getClientWasteNotifications.rejected,
      (state: any, action: any) => {
        state.error = action.payload?.error || null;
      }
    );

    // edit
    builder.addCase(
      editClientWasteNotifications.fulfilled,
      (state: any, action: any) => {
        state.clientWasteNotificationsList =
          state.clientWasteNotificationsList.map((invoice: any) =>
            invoice.id === action.payload.id
              ? { ...invoice, ...action.payload }
              : invoice
          );
      }
    );

    builder.addCase(
      editClientWasteNotifications.rejected,
      (state: any, action: any) => {
        state.error = action.payload?.error || null;
      }
    );

    // add
    builder.addCase(
      addClientWasteNotifications.fulfilled,
      (state: any, action: any) => {
        state.wasteNotification = action.payload;
      }
    );

    builder.addCase(
      addClientWasteNotifications.rejected,
      (state: any, action: any) => {
        state.error = action.payload?.error || null;
      }
    );

    builder.addCase(
      deleteClientWasteNotifications.fulfilled,
      (state: any, action: any) => {
        state.clientWasteNotificationsList = (
          state.clientWasteNotificationsList || []
        ).filter((invoice: any) => invoice.id !== action.payload.id);
      }
    );
    builder.addCase(
      deleteClientWasteNotifications.rejected,
      (state: any, action: any) => {
        state.error = action.payload?.error || null;
      }
    );
  },
});

export default WasteNotificationSlice.reducer;
