import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import CountUp from "react-countup";
import { wasteNotificationWidget } from "../../../Common/data";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../../helpers/url_helper";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { CompanyTypeEnum } from "../../../helpers/enums/company.enum";

const InvoiceWidget = () => {
  const [state, setState] = useState<any>({});
  let navigate = useNavigate();

  const dispatch: any = useDispatch();
  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      user: login.user,
    })
  );
  const { tokens, user } = useSelector(selectAccountAndLogin);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(user?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any, alt: any = null) => {
    return hasPermission(requiredTypes) ? child : alt;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/requisition-metrics`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [dispatch, navigate, tokens]);

  return (
    <React.Fragment>
      <Row>
        {wasteNotificationWidget.map(
          (wasteNotificationWidgetData: any, key: any) => (
            <Col xl={3} md={6} key={key}>
              <Card className={`${wasteNotificationWidgetData.cardClass}`}>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <h4
                        className={`fs-22 fw-semibold ff-secondary mb-2 ${wasteNotificationWidgetData.counterclass}`}
                      >
                        <CountUp
                          end={
                            state[`${wasteNotificationWidgetData.status}`]
                              ?.totalTransactionValue
                          }
                          // end={wasteNotificationWidgetData.counterValue}
                          decimals={2}
                          // suffix="k"
                          prefix="₦"
                        ></CountUp>
                      </h4>
                      <p
                        className={`text-uppercase fw-medium fs-14 ${wasteNotificationWidgetData.textClass}  mb-0`}
                      >
                        {wasteNotificationWidgetData.category ===
                        "Waste Notifications Sent"
                          ? addLayoutData(
                              [
                                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                CompanyTypeEnum.PLATFORM_ADMIN,
                                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                              ],
                              "Waste Notifications Received",
                              "Waste Notifications Sent"
                            )
                          : wasteNotificationWidgetData.category}
                        <span
                          className={`text-${wasteNotificationWidgetData.arrrowTypeColor} fs-14 mb-0 ms-1`}
                        >
                          {wasteNotificationWidgetData.arrowTypeClass ===
                          "up" ? (
                            <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                          ) : (
                            <i className="ri-arrow-right-down-line fs-13 align-middle"></i>
                          )}{" "}
                          {wasteNotificationWidgetData.percentage}
                        </span>
                      </p>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-light rounded-circle fs-3">
                        <i
                          className={`${wasteNotificationWidgetData.catrgoryIcon} fs-24 text-primary`}
                        ></i>
                      </span>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <span
                        className={`badge ${wasteNotificationWidgetData.badgeClass} me-1`}
                      >
                        {wasteNotificationWidgetData.categoryCount}
                      </span>{" "}
                      <span
                        className={`${wasteNotificationWidgetData.textClass}`}
                      >
                        
                        {wasteNotificationWidgetData.categoryCountText ===
                        "Waste Notifications Sent"
                          ? addLayoutData(
                              [
                                CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                                CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                                CompanyTypeEnum.PLATFORM_ADMIN,
                                CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                                CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                              ],
                              "Waste Notifications Received",
                              "Waste Notifications Sent"
                            )
                          : wasteNotificationWidgetData.categoryCountText}
                        {wasteNotificationWidgetData.categoryCountText}
                      </span>
                    </div>
                  </div> */}
                </Card.Body>
              </Card>
            </Col>
          )
        )}
      </Row>
    </React.Fragment>
  );
};

export default InvoiceWidget;
