import { CompanyTypeEnum } from "./company.enum";

export enum VisitationRequisitionTypeEnum {
  SHIP_STAKEHOLDER = "SHIP STAKEHOLDER",
  PLATFORM_STAKEHOLDER = "OIL & GAS PLATFORM STAKEHOLDER",
}

export enum VisitationRequisitionStatusEnum {
  PENDING = "Pending",
  CANCELLED = "Cancelled",
  COMPLETED = "Completed",
}

export enum InspectionStatusEnum {
  AwaitingInspection = "Awaiting Inspection",
  InspectionScheduled = "Inspection Scheduled",
  InspectionCompleted = "Inspection Completed",
}

export enum WasteCollectionStatusEnum {
  AwaitingCollection = "Awaiting Collection",
  CollectionScheduled = "Collection Scheduled",
  CollectionCompleted = "Collection Completed",
  AwaitingAdjustmentConfirmation = 'Awaiting Adjustment Confirmation',
  AdjustmentConfirmed = 'Adjustment Confirmed',
}

export enum PaymentStatusEnum {
  AwaitingPayment = "Awaiting Payment",
  PaymentCompleted = "Payment Completed",
}

export enum WalletEventEnum {
  IN_FLOW = 'IN FLOW',
  OUT_FLOW = 'OUT FLOW',
}

export enum WasteTypeEnum {
  LIQUID_OILY_WASTE_FROM_SHIP_MACHINERY = "LIQUID OILY WASTE FROM SHIP MACHINERY",
  SEWAGE = "SEWAGE",
  GARBAGE = "GARBAGE",
  OILY_CARGO_RESIDUE = "OILY CARGO RESIDUE",
  HAZARDOUS_WASTE = "HAZARDOUS WASTE",
  DRILL_MUD = "DRILL MUD",
  DRILL_CUTTINGS = "DRILL CUTTINGS",
  PRODUCED_WATER = "PRODUCED WATER",
  OIL_AND_CHEMICALS = "OIL AND CHEMICALS",
}

export type WasteTypeKey =
  | "LIQUID_OILY_WASTE_FROM_SHIP_MACHINERY"
  | "SEWAGE"
  | "GARBAGE"
  | "OILY_CARGO_RESIDUE"
  | "HAZARDOUS_WASTE"
  | "DRILL_MUD"
  | "DRILL_CUTTINGS"
  | "PRODUCED_WATER"
  | "OIL_AND_CHEMICALS";

export type WasteTypeSubItemKey = "name" | "category";

export type WasteTypeProps = {
  [key in WasteTypeKey]: {
    [key in WasteTypeSubItemKey]: string | CompanyTypeEnum[];
  };
};

export const WasteType: WasteTypeProps = {
  LIQUID_OILY_WASTE_FROM_SHIP_MACHINERY: {
    name: "LIQUID OILY WASTE FROM SHIP MACHINERY",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
  SEWAGE: {
    name: "SEWAGE",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
  },
  GARBAGE: {
    name: "GARBAGE",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
  },
  OILY_CARGO_RESIDUE: {
    name: "OILY CARGO RESIDUE",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
  HAZARDOUS_WASTE: {
    name: "HAZARDOUS WASTE",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
  },
  DRILL_MUD: {
    name: "DRILL MUD",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
  DRILL_CUTTINGS: {
    name: "DRILL CUTTINGS",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
  PRODUCED_WATER: {
    name: "PRODUCED WATER",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
  OIL_AND_CHEMICALS: {
    name: "OIL AND CHEMICALS",
    category: [CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
  },
};
