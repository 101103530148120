import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Nav,
  ProgressBar,
  Tab,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { continents, countries, languages } from 'countries-list'

import AuthCarousel from "./AuthCarousel";
import logoDark from "../../assets/images/logo-dark.png";
import axios from "axios";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import {
  apiError,
  registerUser,
  resetRegisterFlag,
} from "../../slices/register/thunk";
import { useDispatch } from "react-redux";
import "cleave.js/dist/addons/cleave-phone.ng";
import ReactSelect from "react-select";
import Cleave from "cleave.js/react";

const Signup = () => {
  document.title = "Signup | Mwp.ng Admin & Dashboard Template";

  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState<number>(0);
  const [activeArrowTab, setactiveArrowTab] = useState(1);
  const [locationStatus, setLocationStatus] = useState<any>();
  const [timer, setTimer] = useState<number>(0);

  const [data, setData] = useState<{ [key: string]: any }>();
  const [industries, setIndustries] = useState<{ [key: string]: string }>({});
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleLocationStatus(selectedOption: any) {
    setLocationStatus(selectedOption);
  }

  const selectAccount = createSelector(
    (state: any) => state.Account,
    (account: any) => ({
      success: account.success,
      error: account.error,
      loading: account.loading,
      errorMessage: account.registrationError,
    })
  );

  const { error, success, loading, errorMessage } = useSelector(selectAccount);

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/industry-types`
      );
      // console.log("response", JSON.parse(JSON.stringify(response)));
      setIndustries(JSON.parse(JSON.stringify(response)));
    };

    getData();
  }, []);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: "" || "",
      rcNumber: "" || "",
      companyType: "",
      facilityName: "",
      longitude: "",
      latitude: "",
      address: "",
      state: "",
      country: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Please Enter Your Company Name"),
      rcNumber: Yup.string().required("Please Enter Your RC Number"),
      companyType: Yup.string().required("Please Enter Your User type"),
      longitude: Yup.string().required("Please Enter Your longitude"),
      latitude: Yup.string().required("Please Enter Your latitude"),
      address: Yup.string().required("Please Enter Your address"),
      state: Yup.string().required("Please Enter Your state"),
      country: Yup.string().required("Please Enter Your country"),
    }),
    onSubmit: (values: any) => {
      console.log("values", values);
      setData((prev) => ({ ...prev, ...values }));
      // dispatch(loginUser(values, props.router.navigate));
      // navigate("/dashboard")
      setactiveTab(1);
    },
  });

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => navigate("/login"), 3000);
      setTimer(3);
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, error, navigate]);

  const validation2: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      username: "",
      password: "Strong@password2024" || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Surname"),
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    }),
    onSubmit: (values: any) => {
      console.log("values", values);
      setData((prev) => ({ ...prev, ...values }));
      // dispatch(loginUser(values, props.router.navigate));
      // navigate("/dashboard")
      setactiveTab(2);
    },
  });

  const validation3: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || "",
      // username: "admin" || "",
      // password: "123456" || "",
    },
    // validationSchema: Yup.object({
    //   email: Yup.string().required("Please Enter Your Email"),
    //   username: Yup.string().required("Please Enter Your Username"),
    //   password: Yup.string().required("Please Enter Your Password"),
    // }),
    onSubmit: (values: any) => {
      console.log("onSubmit data", data);
      // setData((prev) => ({ ...prev, ...values }));
      dispatch(
        registerUser({
          ...data,
          facilityName: data?.facilityName || data?.companyName,
          // rcNumber: "RC002344",
          // shipName: "RC002344",
          // flag: "RC002344",
          // imoNumber: "RC002344",
          // mmsiNumber: "RC002344",
        })
      );

      // dispatch(loginUser(values, props.router.navigate));
      // navigate("/dashboard")
      if (error || success) {
        setactiveTab(3);
      }
    },
  });

  const validation4: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("Please Enter Your Code")
        .min(5, "Your verification code must be longer than 4 characters.")
        .max(6, "Your verification code must not be longer than 6 characters."),
    }),
    onSubmit: (values: any) => {
      navigate("/login");
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5">
                      <Link to="/">
                        <span className="logo-lg">
                          <img src={logoDark} alt="" height="21" />
                        </span>
                      </Link>
                    </div>

                    <Card className="my-auto overflow-hidden">
                      <Row className="g-0">
                        <Col lg={6}>
                          <Tab.Container activeKey={activeTab}>
                            <div className="p-lg-5 p-4">
                              <div className="text-center">
                                <h5 className="mb-0">Create New Account</h5>
                                <p className="text-muted mt-2">
                                  Create an account for your company.
                                </p>
                              </div>

                              <div
                                id="custom-progress-bar"
                                className="progress-nav mb-4"
                              >
                                <div>
                                  <ProgressBar
                                    now={activeTab * 40}
                                    style={{ height: "1px" }}
                                  ></ProgressBar>
                                </div>

                                <Nav
                                  as="ul"
                                  variant="pills"
                                  className="progress-bar-tab custom-nav"
                                >
                                  <Nav.Item as="li">
                                    <Nav.Link
                                      as="button"
                                      eventKey="0"
                                      onClick={() => setactiveTab(0)}
                                      className="rounded-pill"
                                    >
                                      1
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li">
                                    <Nav.Link
                                      as="button"
                                      eventKey="1"
                                      onClick={() => setactiveTab(1)}
                                      className="rounded-pill"
                                    >
                                      2
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li">
                                    <Nav.Link
                                      as="button"
                                      eventKey="2"
                                      onClick={() => setactiveTab(2)}
                                      className="rounded-pill"
                                    >
                                      3
                                    </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li">
                                    <Nav.Link
                                      as="button"
                                      eventKey="3"
                                      onClick={() => setactiveTab(3)}
                                      className="rounded-pill"
                                    >
                                      4
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              <Tab.Content>
                                <Tab.Pane
                                  eventKey="0"
                                  id="pills-gen-info"
                                  role="tabpanel"
                                  aria-labelledby="pills-gen-info-tab"
                                >
                                  <div className="mt-4">
                                    <Form
                                      className="auth-input"
                                      onSubmit={validation.handleSubmit}
                                    >
                                      <div className="mb-4">
                                        <div>
                                          <h5 className="mb-1">
                                            Company Details
                                          </h5>
                                          <p className="text-muted">
                                            Fill all Information as below
                                          </p>
                                        </div>
                                      </div>
                                      
                                      <div className="mb-3">
                                        <Form.Label>User type</Form.Label>
                                        <Form.Select
                                          id="datalistOptions"
                                          name="companyType"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.companyType || ""
                                          }
                                          isInvalid={
                                            validation.touched.companyType &&
                                            validation.errors.companyType
                                              ? true
                                              : false
                                          }
                                        >
                                          <option value="">
                                            Select User type
                                          </option>
                                          {Object.keys(industries).map(
                                            (industry) => (
                                              <option
                                                key={industry}
                                                value={industries[industry]}
                                              >
                                                {industries[industry]}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>

                                        {validation.touched.companyType &&
                                        validation.errors.companyType ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation.errors.companyType}
                                          </Form.Control.Feedback>
                                        ) : null}
                                        {/* <div className="position-relative auth-pass-inputgroup mb-3">
                                          <Form.Control
                                            type={
                                              passwordShow ? "text" : "password"
                                            }
                                            name="password"
                                            className="form-control bg-light border-light pe-5 password-input"
                                            placeholder="Enter password"
                                            value={
                                              validation.values.password || ""
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            isInvalid={
                                              validation.touched.password &&
                                              validation.errors.password
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.password &&
                                          validation.errors.password ? (
                                            <Form.Control.Feedback type="invalid">
                                              {validation.errors.password}
                                            </Form.Control.Feedback>
                                          ) : null}
                                          <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                            type="button"
                                            id="password-addon"
                                            onClick={() =>
                                              setPasswordShow(!passwordShow)
                                            }
                                          >
                                            <i className="ri-eye-fill align-middle"></i>
                                          </button>
                                        </div> */}
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="companyName "
                                          className="form-label"
                                        >
                                          Company Name
                                        </label>
                                        <Form.Control
                                          type="companyName"
                                          name="companyName"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter Company Name"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.companyName || ""
                                          }
                                          isInvalid={
                                            validation.touched.companyName &&
                                            validation.errors.companyName
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.companyName &&
                                        validation.errors.companyName ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation.errors.companyName}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="rcNumber"
                                          className="form-label"
                                        >
                                          Registration Number (RC)
                                        </label>
                                        <Form.Control
                                          type="text"
                                          name="rcNumber"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter Registration Number (RC)"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.rcNumber || ""
                                          }
                                          isInvalid={
                                            validation.touched.rcNumber &&
                                            validation.errors.rcNumber
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.rcNumber &&
                                        validation.errors.rcNumber ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation.errors.rcNumber}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      {validation.values.companyType ===
                                      CompanyTypeEnum.OIL_AND_GAS_PLATFORM ? (
                                        <React.Fragment>
                                          <div className="mb-3">
                                            <label
                                              htmlFor="facilityName "
                                              className="form-label"
                                            >
                                              Facility Name
                                            </label>
                                            <Form.Control
                                              type="facilityName"
                                              name="facilityName"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter Facility Name"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values
                                                  .facilityName || ""
                                              }
                                              isInvalid={
                                                validation.touched
                                                  .facilityName &&
                                                validation.errors.facilityName
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.facilityName &&
                                            validation.errors.facilityName ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.facilityName}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </div>

                                          {/* <div className="mb-3">
                                            <label
                                              htmlFor="location"
                                              className="form-label"
                                            >
                                              Location (latitude x longitude)
                                            </label>
                                            <Row>
                                              <Col>
                                                <Form.Control
                                                  type="number"
                                                  name="latitude"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter latitude"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .latitude || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .latitude &&
                                                    validation.errors.latitude
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.latitude &&
                                                validation.errors.latitude ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {validation.errors.latitude}
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                              <Col>
                                                <Form.Control
                                                  type="text"
                                                  name="longitude"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter longitude"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .longitude || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .longitude &&
                                                    validation.errors.longitude
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.longitude &&
                                                validation.errors.longitude ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {validation.errors.longitude}
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </div> */}
                                        </React.Fragment>
                                      ) : null}

                                      {validation.values.companyType ===
                                      CompanyTypeEnum.SHIPMENT ? (
                                        <React.Fragment>
                                          <div className="mb-3">
                                            <label
                                              htmlFor="shipName "
                                              className="form-label"
                                            >
                                              Ship Name
                                            </label>
                                            <Form.Control
                                              type="shipName"
                                              name="shipName"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter Ship Name"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.shipName || ""
                                              }
                                              isInvalid={
                                                validation.touched.shipName &&
                                                validation.errors.shipName
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.shipName &&
                                            validation.errors.shipName ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.shipName}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Row>
                                              <Col>
                                                <label
                                                  htmlFor="imoNumber"
                                                  className="form-label"
                                                >
                                                  Imo Number
                                                </label>
                                                <Form.Control
                                                  type="text"
                                                  name="imoNumber"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter Imo Number"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .imoNumber || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .imoNumber &&
                                                    validation.errors.imoNumber
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.imoNumber &&
                                                validation.errors.imoNumber ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .imoNumber
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                              <Col>
                                                <label
                                                  htmlFor="mmsiNumber"
                                                  className="form-label"
                                                >
                                                  MMSI Number
                                                </label>
                                                <Form.Control
                                                  type="text"
                                                  name="mmsiNumber"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter MMSI Number"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .mmsiNumber || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .mmsiNumber &&
                                                    validation.errors.mmsiNumber
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .mmsiNumber &&
                                                validation.errors.mmsiNumber ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .mmsiNumber
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </div>

                                          <div className="mb-3">
                                            <label
                                              htmlFor="flag"
                                              className="form-label"
                                            >
                                              Flag Bearing
                                            </label>
                                            <Form.Control
                                              type="text"
                                              name="flag"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter Flag Bearing"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.flag || ""
                                              }
                                              isInvalid={
                                                validation.touched.flag &&
                                                validation.errors.flag
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.flag &&
                                            validation.errors.flag ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.flag}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Row>
                                              <Col>
                                                <label
                                                  htmlFor="grossTonnage"
                                                  className="form-label"
                                                >
                                                  Gross Tonnage
                                                </label>
                                                <Form.Control
                                                  type="number"
                                                  name="grossTonnage"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter grossTonnage"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .grossTonnage || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .grossTonnage &&
                                                    validation.errors
                                                      .grossTonnage
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .grossTonnage &&
                                                validation.errors
                                                  .grossTonnage ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .grossTonnage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                              <Col>
                                                <label
                                                  htmlFor="shipType"
                                                  className="form-label"
                                                >
                                                  Ship Type
                                                </label>
                                                <Form.Control
                                                  type="text"
                                                  name="shipType"
                                                  className="form-control bg-light border-light"
                                                  placeholder="Enter shipType"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .shipType || ""
                                                  }
                                                  isInvalid={
                                                    validation.touched
                                                      .shipType &&
                                                    validation.errors.shipType
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.shipType &&
                                                validation.errors.shipType ? (
                                                  <Form.Control.Feedback type="invalid">
                                                    {validation.errors.shipType}
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </Col>
                                            </Row>
                                          </div>
                                        </React.Fragment>
                                      ) : null}

                                      <div className="mb-3">
                                        <label
                                          htmlFor="location"
                                          className="form-label"
                                        >
                                          Location (latitude x longitude)
                                        </label>
                                        <Row className="g-3">
                                          <Col lg={6}>
                                            <Form.Control
                                              type="number"
                                              name="latitude"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter latitude"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.latitude || ""
                                              }
                                              isInvalid={
                                                validation.touched.latitude &&
                                                validation.errors.latitude
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.latitude &&
                                            validation.errors.latitude ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.latitude}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </Col>
                                          <Col lg={6}>
                                            <Form.Control
                                              type="number"
                                              name="longitude"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter longitude"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.longitude ||
                                                ""
                                              }
                                              isInvalid={
                                                validation.touched.longitude &&
                                                validation.errors.longitude
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.longitude &&
                                            validation.errors.longitude ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.longitude}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </Col>
                                          <Col lg={6}>
                                            <label
                                              htmlFor="address"
                                              className="form-label"
                                            >
                                              Address
                                            </label>
                                            <Form.Control
                                              type="text"
                                              name="address"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter address"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.address || ""
                                              }
                                              isInvalid={
                                                validation.touched.address &&
                                                validation.errors.address
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.address &&
                                            validation.errors.address ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.address}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </Col>
                                          <Col lg={6}>
                                            <label
                                              htmlFor="state"
                                              className="form-label"
                                            >
                                              State
                                            </label>
                                            <Form.Control
                                              type="text"
                                              name="state"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter state"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.state || ""
                                              }
                                              isInvalid={
                                                validation.touched.state &&
                                                validation.errors.state
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.state &&
                                            validation.errors.state ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.state}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </Col>
                                          <Col lg={12}>
                                            <label
                                              htmlFor="country"
                                              className="form-label"
                                            >
                                              Country
                                            </label>
                                            <Form.Control
                                              type="text"
                                              name="country"
                                              className="form-control bg-light border-light"
                                              placeholder="Enter country"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.country || ""
                                              }
                                              isInvalid={
                                                validation.touched.country &&
                                                validation.errors.country
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.country &&
                                            validation.errors.country ? (
                                              <Form.Control.Feedback type="invalid">
                                                {validation.errors.country}
                                              </Form.Control.Feedback>
                                            ) : null}
                                          </Col>
                                        </Row>
                                      </div>

                                      <div className="fs-16 pb-2">
                                        <p className="mb-0 fs-14 text-muted fst-italic">
                                          By registering you agree to the
                                          Mwp.ng{" "}
                                          <Link
                                            to="#"
                                            className="text-primary text-decoration-underline fst-normal fw-medium"
                                          >
                                            Terms of Use
                                          </Link>
                                        </p>
                                      </div>

                                      <div className="mt-2">
                                        <button
                                          className="btn btn-primary w-100"
                                          type="submit"
                                        >
                                          Add Contact Person
                                        </button>
                                      </div>

                                      {/* <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                          <h5 className="fs-15 mb-3 title">
                                            Create account with
                                          </h5>
                                        </div>

                                        <ul className="list-inline">
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-primary text-white border-primary"
                                            >
                                              <i className="mdi mdi-facebook"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-info text-white border-info"
                                            >
                                              <i className="mdi mdi-twitter"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-danger text-white border-danger"
                                            >
                                              <i className="mdi mdi-google"></i>
                                            </Link>
                                          </li>
                                        </ul>
                                      </div> */}

                                      <div className="mt-4 text-center">
                                        <p className="mb-0">
                                          Don't have an account ?{" "}
                                          <Link
                                            to="/auth-signin"
                                            className="fw-medium text-primary text-decoration-underline"
                                          >
                                            {" "}
                                            Signin{" "}
                                          </Link>{" "}
                                        </p>
                                      </div>
                                    </Form>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane
                                  eventKey="1"
                                  id="pills-info-desc"
                                  role="tabpanel"
                                  aria-labelledby="pills-info-desc-tab"
                                >
                                  <div className="mt-4">
                                    <Form
                                      onSubmit={validation2.handleSubmit}
                                      className="auth-input"
                                    >
                                      <div className="mb-4">
                                        <div>
                                          <h5 className="mb-1">
                                            Contact Person
                                          </h5>
                                          {/* <p className="text-muted">Fill all Information as below</p> */}
                                        </div>
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="firstName"
                                          className="form-label"
                                        >
                                          First Name
                                        </label>
                                        <Form.Control
                                          type="text"
                                          name="firstName"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter First Name"
                                          onChange={validation2.handleChange}
                                          onBlur={validation2.handleBlur}
                                          value={
                                            validation2.values.firstName || ""
                                          }
                                          isInvalid={
                                            validation2.touched.firstName &&
                                            validation2.errors.firstName
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation2.touched.firstName &&
                                        validation2.errors.firstName ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation2.errors.firstName}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="lastName"
                                          className="form-label"
                                        >
                                          Surname
                                        </label>
                                        <Form.Control
                                          type="text"
                                          name="lastName"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter Surname"
                                          onChange={validation2.handleChange}
                                          onBlur={validation2.handleBlur}
                                          value={
                                            validation2.values.lastName || ""
                                          }
                                          isInvalid={
                                            validation2.touched.lastName &&
                                            validation2.errors.lastName
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation2.touched.lastName &&
                                        validation2.errors.lastName ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation2.errors.lastName}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        {/* <label
                                          htmlFor="phone"
                                          className="form-label"
                                        >
                                          Phone Number
                                        </label> */}
                                        <div className="mb-3 mb-xl-0">
                                          <label
                                            htmlFor="phone"
                                            className="form-label"
                                          >
                                            Phone Number
                                          </label>
                                          <Cleave
                                            name="phone"
                                            placeholder="xxxx xxx xxx"
                                            options={{
                                              phone: true,
                                              phoneRegionCode: "NG",
                                            }}
                                            value={
                                              validation2.values.phone || ""
                                            }
                                            onChange={validation2.handleChange}
                                            className="form-control"
                                          />
                                        </div>
                                        {validation2.touched.phone &&
                                        validation2.errors.phone ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation2.errors.phone}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="email"
                                          className="form-label"
                                        >
                                          Email
                                        </label>
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter email address"
                                          onChange={validation2.handleChange}
                                          onBlur={validation2.handleBlur}
                                          value={validation2.values.email || ""}
                                          isInvalid={
                                            validation2.touched.email &&
                                            validation2.errors.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation2.touched.email &&
                                        validation2.errors.email ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation2.errors.email}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-3">
                                        <label
                                          htmlFor="username"
                                          className="form-label"
                                        >
                                          Username
                                        </label>
                                        <Form.Control
                                          type="text"
                                          name="username"
                                          defaultValue={(validation2.values.email || "").split('@')[0]}
                                          className="form-control bg-light border-light"
                                          placeholder="Enter Username"
                                          onChange={validation2.handleChange}
                                          onBlur={validation2.handleBlur}
                                          value={
                                            validation2.values.username || ""
                                          }
                                          isInvalid={
                                            validation2.touched.username &&
                                            validation2.errors.username
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation2.touched.username &&
                                        validation2.errors.username ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation2.errors.username}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      <div className="mb-2">
                                        <label
                                          htmlFor="userpassword"
                                          className="form-label"
                                        >
                                          Password
                                        </label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                          <Form.Control
                                            type={
                                              passwordShow ? "text" : "password"
                                            }
                                            name="password"
                                            className="form-control bg-light border-light pe-5 password-input"
                                            placeholder="Enter password"
                                            value={
                                              validation2.values.password || ""
                                            }
                                            onChange={validation2.handleChange}
                                            onBlur={validation2.handleBlur}
                                            isInvalid={
                                              validation2.touched.password &&
                                              validation2.errors.password
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation2.touched.password &&
                                          validation2.errors.password ? (
                                            <Form.Control.Feedback type="invalid">
                                              {validation2.errors.password}
                                            </Form.Control.Feedback>
                                          ) : null}
                                          <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                            type="button"
                                            id="password-addon"
                                            onClick={() =>
                                              setPasswordShow(!passwordShow)
                                            }
                                          >
                                            <i className="ri-eye-fill align-middle"></i>
                                          </button>
                                        </div>
                                      </div>

                                      <div className="fs-16 pb-2">
                                        <p className="mb-0 fs-14 text-muted fst-italic">
                                          By registering you agree to the
                                          Mwp.ng{" "}
                                          <Link
                                            to="#"
                                            className="text-primary text-decoration-underline fst-normal fw-medium"
                                          >
                                            Terms of Use
                                          </Link>
                                        </p>
                                      </div>

                                      <div className="mt-2">
                                        <button
                                          className="btn btn-primary w-100"
                                          type="submit"
                                        >
                                          Confirm
                                        </button>
                                      </div>

                                      {/* <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                          <h5 className="fs-15 mb-3 title">
                                            Create account with
                                          </h5>
                                        </div>

                                        <ul className="list-inline">
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-primary text-white border-primary"
                                            >
                                              <i className="mdi mdi-facebook"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-info text-white border-info"
                                            >
                                              <i className="mdi mdi-twitter"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-danger text-white border-danger"
                                            >
                                              <i className="mdi mdi-google"></i>
                                            </Link>
                                          </li>
                                        </ul>
                                      </div> */}

                                      <div className="mt-4 text-center">
                                        <p className="mb-0">
                                          Don't have an account ?{" "}
                                          <Link
                                            to="/auth-signin"
                                            className="fw-medium text-primary text-decoration-underline"
                                          >
                                            {" "}
                                            Signin{" "}
                                          </Link>{" "}
                                        </p>
                                      </div>
                                    </Form>
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane
                                  eventKey="2"
                                  id="pills-confirm-info"
                                  role="tabpanel"
                                  aria-labelledby="pills-confirm-info-tab"
                                >
                                  <div className="mt-4">
                                    <Form
                                      onSubmit={validation3.handleSubmit}
                                      className="auth-input"
                                    >
                                      <div className="mb-4">
                                        <div>
                                          <h5 className="mb-1">Confirm</h5>
                                          <p className="text-muted">
                                            COMPANY DETAILS
                                          </p>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          Company Name:
                                        </label>
                                        <div
                                          className={`${
                                            !validation.values.companyName
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values.companyName
                                            ? "Please add your company name"
                                            : validation.values.companyName}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          RC Name:
                                        </label>
                                        <div
                                          className={`${
                                            !validation.values.rcNumber
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values.rcNumber
                                            ? "Please add your rc number"
                                            : validation.values.rcNumber}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          User type:
                                        </label>
                                        <div
                                          className={`${
                                            !validation.values.companyType
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation.values.companyType
                                            ? "Please add your User type"
                                            : validation.values.companyType}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          Location: (lat x long)
                                        </label>
                                        <Row>
                                          <Col>
                                            <div
                                              className={`${
                                                !validation.values.latitude
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {!validation.values.latitude
                                                ? "Please add your Location"
                                                : validation.values.latitude}
                                            </div>
                                          </Col>
                                          <Col className="text-success">x</Col>
                                          <Col>
                                            <div
                                              className={`${
                                                !validation.values.longitude
                                                  ? "text-danger"
                                                  : ""
                                              }`}
                                            >
                                              {!validation.values.longitude
                                                ? "Please add your Location"
                                                : validation.values.longitude}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>

                                      <div className="mb-4">
                                        <div>
                                          {/* <h5 className="mb-1">Confirm</h5> */}
                                          <p className="text-muted">
                                            CONTACT DETAILS
                                          </p>
                                          <hr></hr>
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          First Name:
                                        </label>
                                        <div
                                          className={`${
                                            !validation2.values.firstName
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation2.values.firstName
                                            ? "Please add your first name"
                                            : validation2.values.firstName}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="surname"
                                          className="form-label col-5"
                                        >
                                          Surname:
                                        </label>
                                        <div
                                          className={`${
                                            !validation2.values.lastName
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation2.values.lastName
                                            ? "Please add your surname"
                                            : validation2.values.lastName}
                                        </div>
                                      </div>

                                      
                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="username"
                                          className="form-label col-5"
                                        >
                                          username:
                                        </label>
                                        <div
                                          className={`${
                                            !validation2.values.username
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation2.values.username
                                            ? "Please add your username"
                                            : validation2.values.username}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="phone"
                                          className="form-label col-5"
                                        >
                                          Phone:
                                        </label>
                                        <div
                                          className={`${
                                            !validation2.values.phone
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation2.values.phone
                                            ? "Please add your Phone"
                                            : validation2.values.phone}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex">
                                        <label
                                          htmlFor="email"
                                          className="form-label col-5"
                                        >
                                          Email:
                                        </label>
                                        <div
                                          className={`${
                                            !validation2.values.email
                                              ? "text-danger"
                                              : ""
                                          }`}
                                        >
                                          {!validation2.values.email
                                            ? "Please add your Email"
                                            : validation2.values.email}
                                        </div>
                                      </div>

                                      <div className="mb-3 d-flex text-danger">
                                        {errorMessage}
                                      </div>

                                      <div className="mt-2">
                                        <button
                                          className="btn btn-primary w-100"
                                          type="submit"
                                        >
                                          {loading && (
                                            <Spinner
                                              size="sm"
                                              animation="border"
                                            />
                                          )}{" "}
                                          {"  "}
                                          Submit
                                        </button>
                                      </div>

                                      {/* <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                          <h5 className="fs-15 mb-3 title">
                                            Create account with
                                          </h5>
                                        </div>

                                        <ul className="list-inline">
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-primary text-white border-primary"
                                            >
                                              <i className="mdi mdi-facebook"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-info text-white border-info"
                                            >
                                              <i className="mdi mdi-twitter"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-danger text-white border-danger"
                                            >
                                              <i className="mdi mdi-google"></i>
                                            </Link>
                                          </li>
                                        </ul>
                                      </div> */}

                                      <div className="mt-4 text-center">
                                        <p className="mb-0">
                                          Don't have an account ?{" "}
                                          <Link
                                            to="/auth-signin"
                                            className="fw-medium text-primary text-decoration-underline"
                                          >
                                            {" "}
                                            Signin{" "}
                                          </Link>{" "}
                                        </p>
                                      </div>
                                    </Form>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane
                                  eventKey="3"
                                  id="pills-success"
                                  role="tabpanel"
                                  aria-labelledby="pills-success-tab"
                                >
                                  <div className="mt-4">
                                    <Form
                                      onSubmit={validation4.handleSubmit}
                                      className="auth-input"
                                    >
                                      <div className="mb-4">
                                        <div>
                                          <h5 className="mb-1">Confirm</h5>
                                          {/* <p className="text-muted">Fill all Information as below</p> */}
                                        </div>
                                      </div>
                                      {/* <div className="mb-3">
                                        <label
                                          htmlFor="email "
                                          className="form-label"
                                        >
                                          Email
                                        </label>
                                        <Form.Control
                                          type="email"
                                          name="email"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter email address"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.email || ""}
                                          isInvalid={
                                            validation.touched.email &&
                                            validation.errors.email
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.email &&
                                        validation.errors.email ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation.errors.email}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div> */}

                                      <div className="mb-3">
                                        <label
                                          htmlFor="code"
                                          className="form-label"
                                        >
                                          Email verification code
                                        </label>
                                        <Form.Control
                                          type="number"
                                          name="code"
                                          className="form-control bg-light border-light"
                                          placeholder="Enter code"
                                          onChange={validation4.handleChange}
                                          onBlur={validation4.handleBlur}
                                          value={validation4.values.code || ""}
                                          isInvalid={
                                            validation4.touched.code &&
                                            validation4.errors.code
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation4.touched.code &&
                                        validation4.errors.code ? (
                                          <Form.Control.Feedback type="invalid">
                                            {validation4.errors.code}
                                          </Form.Control.Feedback>
                                        ) : null}
                                      </div>

                                      {/* <div className="mb-2">
                                        <label
                                          htmlFor="userpassword"
                                          className="form-label"
                                        >
                                          Password
                                        </label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                          <Form.Control
                                            type={
                                              passwordShow ? "text" : "password"
                                            }
                                            name="password"
                                            className="form-control bg-light border-light pe-5 password-input"
                                            placeholder="Enter password"
                                            value={
                                              validation.values.password || ""
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            isInvalid={
                                              validation.touched.password &&
                                              validation.errors.password
                                                ? true
                                                : false
                                            }
                                          />
                                          {validation.touched.password &&
                                          validation.errors.password ? (
                                            <Form.Control.Feedback type="invalid">
                                              {validation.errors.password}
                                            </Form.Control.Feedback>
                                          ) : null}
                                          <button
                                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                            type="button"
                                            id="password-addon"
                                            onClick={() =>
                                              setPasswordShow(!passwordShow)
                                            }
                                          >
                                            <i className="ri-eye-fill align-middle"></i>
                                          </button>
                                        </div>
                                      </div> */}

                                      {/* <div className="fs-16 pb-2">
                                        <p className="mb-0 fs-14 text-muted fst-italic">
                                          By registering you agree to the
                                          Mwp.ng{" "}
                                          <Link
                                            to="#"
                                            className="text-primary text-decoration-underline fst-normal fw-medium"
                                          >
                                            Terms of Use
                                          </Link>
                                        </p>
                                      </div> */}

                                      <div className="mt-2">
                                        <button
                                          className="btn btn-primary w-100"
                                          type="submit"
                                        >
                                          Verify email
                                        </button>
                                      </div>

                                      {/* <div className="mt-4 text-center">
                                        <div className="signin-other-title">
                                          <h5 className="fs-15 mb-3 title">
                                            Create account with
                                          </h5>
                                        </div>

                                        <ul className="list-inline">
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-primary text-white border-primary"
                                            >
                                              <i className="mdi mdi-facebook"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-info text-white border-info"
                                            >
                                              <i className="mdi mdi-twitter"></i>
                                            </Link>
                                          </li>
                                          <li className="list-inline-item">
                                            <Link
                                              to="#"
                                              className="social-list-item bg-danger text-white border-danger"
                                            >
                                              <i className="mdi mdi-google"></i>
                                            </Link>
                                          </li>
                                        </ul>
                                      </div> */}

                                      <div className="mt-4 text-center">
                                        <p className="mb-0">
                                          Don't have an account ?{" "}
                                          <Link
                                            to="/auth-signin"
                                            className="fw-medium text-primary text-decoration-underline"
                                          >
                                            {" "}
                                            Signin{" "}
                                          </Link>{" "}
                                        </p>
                                      </div>
                                    </Form>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </div>
                          </Tab.Container>
                        </Col>

                        <AuthCarousel />
                      </Row>
                    </Card>

                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        ©
                        <script>
                          document.write(new Date().getFullYear())
                        </script>{" "}
                        Mwp.ng.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Signup;
