import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { addWasteNotificationCollections as onScheduleWasteCollection } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import { WasteCollectionStatusEnum } from "../../helpers/enums/visitRequisition.enum";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handleScheduledCollection: (data: any, resetFunc: any) => Promise<void>;
  data: any;
}

const ScheduleWasteCollection = ({
  isShow,
  handleClose,
  handleShow,
  handleScheduledCollection,
  data = {},
}: userProps) => {
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      collectionDate: data?.collectionScheduledDate || "",
    },
    validationSchema: Yup.object({
      collectionDate: Yup.string().required(
        "Please Enter Your Collection Date"
      ),
    }),

    onSubmit: (values: any) => {
      const NewWasteNotificationCollection = {
        // users: values["users"].map((u: any) => u?.value),
        collectionDate:
          values["collectionDate"].length > 3
            ? values["collectionDate"]
            : data?.collectionScheduledDate,
      };
      handleScheduledCollection(NewWasteNotificationCollection, () =>
        formik.resetForm()
      );
    },
  });

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Schedule Collection</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Collection Date</Form.Label>
                <input
                  name="collectionDate"
                  type="date"
                  id="date-field"
                  className="form-control bg-light border-0"
                  placeholder="Collection Date"
                  readOnly={
                    data.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.AwaitingCollection &&
                    data.wasteCollectionStatus !==
                      WasteCollectionStatusEnum.CollectionScheduled
                  }
                  onChange={(e: any) => formik.handleChange(e)}
                  value={formik.values.collectionDate}
                  // value={[formik.values.collectionDate] || ""}
                />
                {formik.errors.collectionDate &&
                formik.touched.collectionDate ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.collectionDate}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-primary">
                  Schedule Collection
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleWasteCollection;
