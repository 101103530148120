import { createSlice } from "@reduxjs/toolkit";

import { getWalletMetrics, getWalletHistories, getWallets, addWallet, editWallet, deleteWallet } from "./thunk";

export const initialState = {
  wallet: {},
  metrics: {},
  walletHistories: [],
  error: {},
};

const WalletSlice: any = createSlice({
  name: "Wallet",
  initialState,
  reducers: {},

  extraReducers: (builder: any) => {
    builder.addCase(getWallets.fulfilled, (state: any, action: any) => {
      state.wallet = action.payload;
    });

    builder.addCase(getWallets.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
      state.wallet = {};
    });

    
    builder.addCase(getWalletMetrics.fulfilled, (state: any, action: any) => {
      state.metrics = action.payload;
    });

    builder.addCase(getWalletMetrics.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });


    // add
    builder.addCase(addWallet.fulfilled, (state: any, action: any) => {
      state.wallet = action.payload?.data;
    });
    builder.addCase(addWallet.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(editWallet.fulfilled, (state: any, action: any) => {
      // state.wallet = state.wallet;
    });

    builder.addCase(editWallet.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    builder.addCase(deleteWallet.fulfilled, (state: any, action: any) => {
      state.wallet = (state.wallet || {});
    });
    builder.addCase(deleteWallet.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
    });

    
    builder.addCase(getWalletHistories.fulfilled, (state: any, action: any) => {
      state.walletHistories = action.payload;
    });

    builder.addCase(getWalletHistories.rejected, (state: any, action: any) => {
      state.error = action.payload?.error || null;
      state.walletHistories = [];
    });

  },
});

export default WalletSlice.reducer;
