import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import * as Yup from "yup";

import { editWasteManagements as onEditWasteManagements } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import { PatternFormat } from "react-number-format";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

interface wasteManagementeditProps {
  isShow: any;
  handleClose: any;
  edit: any;
  // isEdit: any
}

const EditWasteManagements = ({
  isShow,
  handleClose,
  edit,
}: wasteManagementeditProps) => {
  // image
  const [selectedImage, setSelectedImage] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      formik.setFieldValue("logo", e.target.result);
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const dispatch = useDispatch();

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: (edit && edit.logo) || "",
      id: (edit && edit.id) || "",
      name: (edit && edit.name) || "",
      state: (edit && edit.state) || "",
      country: (edit && edit.country) || "",
      // date: (edit && edit.date) || "",
      longitude: (edit && edit.longitude) || "",
      latitude: (edit && edit.latitude) || "",
      address: (edit && edit.address) || "",
      status: (edit && edit.status) || "",
      // registeredOn: (edit && edit.registeredOn) || "",
    },
    validationSchema: Yup.object({
      // memberName: Yup.string().required("Please Enter Facility Name"),
      logo: Yup.string().required("Please Select Facility Logo"),
      name: Yup.string().required("Please Enter Facility Name"),
      longitude: Yup.string().required("Please Select longitude"),
      latitude: Yup.string().required("Please Enter latitude"),
      address: Yup.string().required("Please Enter your address"),
      // registeredOn: Yup.string().required("Please Select registered date"),
      // status: Yup.string().required("Please choose Your status"),
    }),

    onSubmit: (values: any) => {
      setLoading(true);
      const UpdateWasteManagement = {
        id: values.id,
        // memberName: values.memberName,
        // logo: values.logo,
        name: values.name,
        longitude: values.longitude,
        latitude: values.latitude,
        address: values.address,
        state: values.state,
        country: values.country,
        // registeredOn: values.registeredOn,
        // status: values.status,
      };

      dispatch(onEditWasteManagements(UpdateWasteManagement));
      formik.resetForm();
    },
  });

  const selectStates = createSelector(
    (state: any) => state.Facility,
    (Facility: any) => ({
      wastesList: Facility.wastesList,
      error: Facility.error,
    })
  );

  const { wastesList, error } = useSelector(selectStates);

  useEffect(() => {
    if (loading && wastesList) {
      console.log("error", error);
      handleClose();
      setLoading(false);
    }
  }, [error, handleClose, loading, wastesList]);

  useEffect(() => {
    setSelectedImage(edit?.logo);
  }, [edit]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Edit Port Reception</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="text-center">
                <div className="position-relative d-inline-block">
                  <div className="position-absolute bottom-0 end-0">
                    <Form.Label
                      htmlFor="product-image-input"
                      className="mb-0"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Select Image"
                    >
                      <div className="avatar-xs cursor-pointer">
                        <div className="avatar-title bg-light border rounded-circle text-muted">
                          <i className="ri-image-fill"></i>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      name="logo"
                      className="form-control d-none"
                      value=""
                      id="product-image-input"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleImageChange}
                    />
                  </div>
                  <div className="avatar-lg p-1">
                    <div className="avatar-title bg-light rounded-circle">
                      <img
                        src={selectedImage || dummy}
                        alt=""
                        id="product-img"
                        className="avatar-md rounded-circle object-cover"
                      />
                    </div>
                  </div>
                </div>
                {formik.errors.logo && formik.touched.logo ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {" "}
                    {formik.errors.logo}{" "}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="users">Name</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter facility name"
                  value={formik.values.name || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.name}
                />
                {formik.errors.name && formik.touched.name ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  placeholder="Enter Your email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div> */}
              {/* <div className="mb-3">
                <Form.Label htmlFor="users">Mobile</Form.Label>
                <PatternFormat
                  id="mobile"
                  name="mobile"
                  className="form-control"
                  placeholder="Enter Your Mobile"
                  value={formik.values.mobile || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  format="###-###-####"
                />
                {formik.errors.mobile && formik.touched.mobile ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.mobile}
                  </Form.Control.Feedback>
                ) : null}
              </div> */}
              {/* <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Registered Date</Form.Label>
                    <FlatPickr
                      className="form-control"
                      id="registeredOn"
                      name="registeredOn"
                      placeholder="Select date"
                      options={{
                        mode: "single",
                        dateFormat: "d M, Y",
                      }}
                      onChange={(registeredOn: any) =>
                        formik.setFieldValue(
                          "registeredOn",
                          moment(registeredOn[0]).format("DD MMMM ,YYYY")
                        )
                      }
                      value={formik.values.registeredOn || ""}
                    />
                    {formik.errors.registeredOn &&
                    formik.touched.registeredOn ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.registeredOn}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <Form.Select
                      id="paymentType"
                      name="status"
                      placeholder="Enter Payment type"
                      value={formik.values.status || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.status}
                    >
                      <option disabled>Select Status</option>
                      <option value="Active">Active</option>
                      <option value="Disabled">Disabled</option>
                    </Form.Select>
                    {formik.errors.status && formik.touched.status ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.status}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
              </Row> */}
              <div className="mb-3">
                <Form.Label htmlFor="Name">Address</Form.Label>
                <Form.Control
                  id="address"
                  name="address"
                  placeholder="Enter address"
                  value={formik.values.address || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.address}
                />
                {formik.errors.address && formik.touched.address ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">State</Form.Label>
                    <Form.Control
                      id="state"
                      name="state"
                      placeholder="Enter state"
                      value={formik.values.state || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.state}
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.state}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Country</Form.Label>
                    <Form.Control
                      id="country"
                      name="country"
                      placeholder="Enter country"
                      value={formik.values.country || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.country}
                    />
                    {formik.errors.country && formik.touched.country ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.country}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Latitude</Form.Label>
                    <Form.Control
                      id="latitude"
                      name="latitude"
                      type="number"
                      placeholder="Enter latitude"
                      value={formik.values.latitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.latitude}
                    />
                    {formik.touched.latitude && formik.errors.latitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.latitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="Name">Longitude</Form.Label>
                    <Form.Control
                      id="longitude"
                      name="longitude"
                      type="number"
                      placeholder="Enter longitude"
                      value={formik.values.longitude || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.longitude}
                    />
                    {formik.errors.longitude && formik.touched.longitude ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.longitude}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col>
                {/* <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <Form.Select
                      id="oilplatformType"
                      name="status"
                      placeholder="Enter OilPlatform type"
                      value={formik.values.status || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={!!formik.errors.status}
                    >
                      <option>Select Status</option>
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                      <option value="Failed">Failed</option>
                    </Form.Select>
                    {formik.errors.status && formik.touched.status ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {formik.errors.status}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                </Col> */}
              </Row>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={handleClose}
                >
                  Edit WasteManagement
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default EditWasteManagements;
