import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { addWasteCollections as onAddMoney } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { PatternFormat } from "react-number-format";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handlePayment: any;
  amountDue: any;
}

const AddMoney = ({
  isShow,
  handleClose,
  handleShow,
  handlePayment,
  amountDue,
}: userProps) => {
  const [state, setState] = useState({ amount: "" })

  const onChange = ({ target: { name, value } }: any) => {
    setState(prev => ({ ...prev, [name]: value }));
  }

  console.log('amountDue')
  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      title: `Collected waste on ${new Date()}`,
      findings: "",
      comments: "",
      amount: state.amount,
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Please Enter an amount"),
    }),

    onSubmit: (values: any) => {
      // dispatch(onAddMoney(NewWasteCollection));
      handlePayment(Number(values.amount), () => {
        formik.resetForm()
        setState(prev => ({ ...prev, amount: '' }));
      });
    },
  });

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Add Money</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Card Holder's Name</Form.Label>
                <input
                  className="form-control bg-light border-0"
                  type="text"
                  id="cardholderName"
                  placeholder="Card Holder Name"
                />
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="users">Number</Form.Label>
                <PatternFormat
                  className="form-control bg-light border-0"
                  id="cardNumber"
                  name="cardNumber"
                  placeholder="xxxx xxxx xxxx xxxx"
                  format="####-####-#####-####"
                />
              </div>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="users">Expiry Date</Form.Label>
                    <PatternFormat
                      className="form-control bg-light border-0"
                      id="expDate"
                      name="expDate"
                      placeholder="xx/xx"
                      format="##/##"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Label htmlFor="users">Cvv</Form.Label>
                    <PatternFormat
                      className="form-control bg-light border-0"
                      id="cvv"
                      name="cvv"
                      placeholder="xxx"
                      format="###"
                    />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <Form.Label htmlFor="users">Amount</Form.Label>
                <input
                  className="form-control bg-light border-0"
                  type="number"
                  id="amount"
                  name="amount"
                  placeholder="₦0.00"
                  // step="0.01"
                  value={state.amount}
                  onChange={onChange}
                  // onBlur={formik.handleBlur}
                  // isInvalid={!!formik.errors.amount}
                />
                {/* {formik.errors.amount && formik.touched.amount ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.amount}
                  </Form.Control.Feedback>
                ) : null} */}
              </div>

              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Pay Now
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddMoney;
