import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown } from "react-bootstrap";
import { invoiceList } from "../../Common/data/index";
import { Link, useNavigate } from "react-router-dom";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import axios from "axios";
import { GET_BILLS } from "../../helpers/url_helper";
import { PaymentStatusEnum } from "../../helpers/enums/bill.enum";

const InvoiceList = () => {
  const [billList, setBillList] = useState<any>([]);
  const [bills, setBills] = useState<any>([]);
  const navigate = useNavigate();

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { company, tokens } = useSelector(selectAccountAndLogin);

  const statusClass = {
    [PaymentStatusEnum.AwaitingPayment]: "bg-warning-subtle text-warning",
    [PaymentStatusEnum.PaymentCompleted]: "bg-success-subtle text-success",
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_BILLS}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setBillList(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate, tokens]);

  useEffect(() => {
    setBills(billList);
  }, [billList]);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? child : null;
  };

  return (
    <React.Fragment>
      <Col
        xl={
          company.companyType === CompanyTypeEnum.PORT_RECEPTION_FACILITIES ||
          company.companyType === CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING ||
          company.companyType ===
            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY ||
          company.companyType === CompanyTypeEnum.NIGERIA_PORT_AUTHORITY
            ? 5
            : 12
        }
      >
        <Card>
          <Card.Header className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Recent Bills</h4>
            <Dropdown>
              <Dropdown.Toggle as="a" className="text-reset arrow-none">
                <span className="fw-semibold text-uppercase fs-14">
                  Sort By:{" "}
                </span>{" "}
                <span className="text-muted">
                  Weekly<i className="las la-angle-down fs-12 ms-2"></i>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <Dropdown.Item>Monthly</Dropdown.Item>
                <Dropdown.Item>Yearly</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Card.Header>
          <Card.Body className="pt-2">
            <div className="table-responsive table-card">
              <table className="table table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-muted text-uppercase">
                    <th style={{ width: "50px" }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="checkAll"
                          value="option"
                        />
                      </div>
                    </th>
                    <th scope="col">Invoice ID</th>
                    {addLayoutData(
                      [
                        CompanyTypeEnum.PLATFORM_ADMIN,
                        CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                        CompanyTypeEnum.SHIPMENT,
                      ],
                      <th scope="col">SHIP</th>
                    )}
                    {addLayoutData(
                      [
                        CompanyTypeEnum.PLATFORM_ADMIN,
                        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                        CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                      ],
                      <th scope="col">PLATFORM</th>
                    )}
                    {/* <th scope="col">Client</th> */}
                    <th scope="col">Date</th>
                    <th scope="col">AMOUNT BILLED (NGN)</th>
                    <th scope="col" style={{ width: "16%" }}>
                      Status
                    </th>
                    {/* <th scope="col" style={{ width: "12%" }}>
                      Action
                    </th> */}
                  </tr>
                </thead>

                <tbody>
                  {bills.slice(0, 5).map((billsData: any, key: any) => (
                    <tr key={key}>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="check1"
                            value="option"
                          />
                        </div>
                      </td>
                      <td>
                        <p className="mb-0">{billsData.billNumber}</p>
                      </td>
                      <td>
                        {/* <img
                          src={billsData.clientImg}
                          alt=""
                          className="avatar-xs rounded-circle me-2"
                        /> */}

                        {addLayoutData(
                          [
                            CompanyTypeEnum.PLATFORM_ADMIN,
                            CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
                            CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
                            CompanyTypeEnum.SHIPMENT,
                          ],
                          <Link to="#" className="text-body align-middle">
                            {billsData?.visitationRequisition?.ship?.name}
                          </Link>
                        )}
                        {addLayoutData(
                          [
                            CompanyTypeEnum.PLATFORM_ADMIN,
                            CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
                            CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
                            CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
                          ],
                          <Link to="#" className="text-body align-middle">
                            {
                              billsData?.visitationRequisition?.oilPlatform
                                ?.name
                            }
                          </Link>
                        )}
                      </td>
                      <td>
                        {billsData.createDateTime
                          ? new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            }).format(new Date(billsData.createDateTime))
                          : ""}
                      </td>
                      <td>
                        {(billsData.amountDue || 0).toLocaleString(
                          navigator.language,
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </td>
                      <td>
                        <span
                          className={`badge ${
                            statusClass[billsData.status as PaymentStatusEnum]
                          } p-2`}
                        >
                          {billsData.status}
                        </span>
                      </td>
                      {/* <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            as="button"
                            className="btn btn-soft-secondary btn-sm arrow-none"
                            size="sm"
                          >
                            <i className="las la-ellipsis-h align-middle fs-18"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-end">
                            <li>
                              <Dropdown.Item>
                                <i className="las la-eye fs-18 align-middle me-2 text-muted"></i>
                                View
                              </Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item>
                                <i className="las la-pen fs-18 align-middle me-2 text-muted"></i>
                                Edit
                              </Dropdown.Item>
                            </li>
                            <li>
                              <Dropdown.Item>
                                <i className="las la-file-download fs-18 align-middle me-2 text-muted"></i>
                                Download
                              </Dropdown.Item>
                            </li>
                            <li className="dropdown-divider"></li>
                            <li>
                              <Dropdown.Item
                                className="remove-item-btn"
                                href="#"
                              >
                                <i className="las la-trash-alt fs-18 align-middle me-2 text-muted"></i>
                                Delete
                              </Dropdown.Item>
                            </li>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InvoiceList;
