import React, { useState } from "react";
import RigTable from "./RigTable";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../../Common/BreadCrumb";

const Rigs = () => {
  document.title = "Rig | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hideRigModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Rigs" title="Rigs" />
          <RigTable isShow={isShow} hideRigModal={hideRigModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Rigs;
