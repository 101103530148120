import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
// import { addWasteCollections as onAddWasteCollections } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { PatternFormat } from "react-number-format";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getPortOfOperations as onGetPortOfOperations } from "../../slices/thunk";
import Dropzone from "react-dropzone";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handleInitiatedCollection: (data: any, resetFunc: any) => Promise<void>;
}

const AddWasteCollections = ({
  isShow,
  handleClose,
  handleShow,
  handleInitiatedCollection
}: userProps) => {
  // image
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      title: `Collected waste on ${new Date()}`,
      // findings: "",
      comments: "Waste Collected",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Inspection title"),
      // findings: Yup.string().required("Please Enter Your Findings"),
      comments: Yup.string().required("Please Enter Your Comments"),
      // pooId: Yup.string().required("Please Select Port of Operation"),
    }),

    onSubmit: (values: any) => {
      let newid = Math.floor(Math.random() * (30 - 20)) + 20;
      const NewWasteCollection = {
        // id: newid,
        title: values["title"],
        comments: values["comments"],
      };
      handleInitiatedCollection(NewWasteCollection, () => formik.resetForm());

    },
  });

  useEffect(() => {
    setSelectedImage("");
  }, [handleClose]);


  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Waste Collections Form</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Title</Form.Label>
                <Form.Control
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter Inspection title"
                  value={formik.values.title || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.title}
                />
                {formik.errors.title && formik.touched.title ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.title}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              {/* <div className="mb-3">
                <Form.Label htmlFor="Email-input">
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="Email-input"
                  name="email"
                  placeholder="Enter Your email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                ) : null}
              </div> */}
              {/* <div className="mb-3">
                <Form.Label htmlFor="users">Findings</Form.Label>
                <Form.Control
                  id="findings"
                  name="findings"
                  as="textarea"
                  className="form-control"
                  placeholder="Enter Your Findings"
                  value={formik.values.findings || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.findings && formik.touched.findings ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.findings}
                  </Form.Control.Feedback>
                ) : null}
              </div> */}

              <div className="mb-3">
                <Form.Label htmlFor="Comments">Comments</Form.Label>
                <Form.Control
                  id="comments"
                  as="textarea"
                  name="comments"
                  placeholder="Enter comments"
                  value={formik.values.comments || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.comments}
                />
                {formik.errors.comments && formik.touched.comments ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.comments}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="attachment">Attachments</Form.Label>
                <Dropzone
                  onDrop={(acceptedFiles: any) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps }: any) => (
                    <div className="dropzone dz-clickable text-center">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Confirm Collection
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddWasteCollections;
