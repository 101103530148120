import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import ProffesionalWidgets from "./ProffesionalWidgets";
import PaymentActivity from "./PaymentActivity";
import QuickInvoice from "./QuickInvoice";
import SalesRevenue from "./SalesRevenue";
// import PaymentOverview from "./PaymentOverview";

const Dashboard = () => {
  document.title = "Dashboard | Mwp.ng Admin & Dashboard";

  // useEffect(() => {
  //   window.location.reload()
  // }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Dashboard" title="Dashboard" />
          <ProffesionalWidgets />
          <PaymentActivity />
          <QuickInvoice />
          {/* <PaymentOverview /> */}
          <SalesRevenue />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
