import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import TableContainer from "../../Common/Tabledata/TableContainer";
import { DeleteModal } from "../../Common/DeleteModal";
import { Link } from "react-router-dom";
import { Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import {
  getWallets as onGetWallets,
  getWalletMetrics as onGetWalletMetrics,
  getWalletHistories as onGetWalletHistories,
  deleteWallet as onDeleteWallet,
} from "../../slices/thunk";
import { handleSearchData } from "../../Common/Tabledata/SorttingData";
import NoSearchResult from "../../Common/Tabledata/NoSearchResult";
import EditWasteNotification from "../../Common/CrudModal/EditWasteNotification";
import WasteNotificationWidget from "./WalletWidget";
import { CompanyTypeEnum } from "../../helpers/enums/company.enum";
import WasteNotificationChatModal from "../../Common/CrudModal/WasteNotificationChatModal";
import {
  WalletEventEnum,
  VisitationRequisitionStatusEnum,
  WasteCollectionStatusEnum,
} from "../../helpers/enums/visitRequisition.enum";
import { PaymentStatusEnum } from "../../helpers/enums/bill.enum";
import AddMoney from "../../Common/CrudModal/AddMoney";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../helpers/url_helper";
import axios from "axios";
import { toast } from "react-toastify";

const WalletsTable = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const selectClientWasteNotificationList = createSelector(
    (state: any) => state.Wallet,
    (state: any) => state.Login,
    (wallet: any, login: any) => ({
      walletHistories: wallet.walletHistories,
      loggedInUser: login.user,
      company: login.user.company,
      tokens: login.tokens,
    })
  );

  const { walletHistories, company, loggedInUser, tokens } = useSelector(
    selectClientWasteNotificationList
  );

  // const [state, setState] = useState<any>({});

  const [wasteNotifications, setWasteNotifications] = useState<any>([]);

  const [paymentWasteNotifications, setPaymentWasteNotifications] =
    useState<boolean>(false);
  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(loggedInUser?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? [child] : [];
  };
  const handleClosePayment = () => setPaymentWasteNotifications(false);

  const handlePaymentWasteNotifications = () => {
    setPaymentWasteNotifications(true);
  };

  const addMoney = async (amount: any, resetFunc: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/add-money`,
        { amount },
        { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
      );
      if (resetFunc) resetFunc();
      toast.success("Payment Successful", {
        autoClose: 2000,
      });
      handleClosePayment();

      dispatch(onGetWallets());
      dispatch(onGetWalletHistories());
      dispatch(onGetWalletMetrics());
    } catch (error: any) {
      // console.error("error", error);
      console.log("typeof error", typeof error);
      if (typeof error == "string" && error.includes("401")) {
        const message = "Invalid or expired token";
        console.log("message", message);
        toast.error(message, { autoClose: 2000 });

        navigate(process.env.PUBLIC_URL + "/logout");
      } else {
        console.log('error', error)
        toast.error('You have reached the maximum wallet amount', { autoClose: 2000 });
      }
    }
  };

  useEffect(() => {
    dispatch(onGetWallets());
    dispatch(onGetWalletHistories());
  }, [dispatch]);

  useEffect(() => {
    setWasteNotifications(walletHistories);
  }, [walletHistories]);

  // Delete modal

  const [delet, setDelet] = useState<boolean>(false);
  const [deletid, setDeletid] = useState<any>();
  const handleDeleteModal = useCallback(
    (id: any) => {
      setDelet(!delet);
      setDeletid(id);
    },
    [delet]
  );

  // const handleDeleteId = () => {
  //   dispatch(onDeleteClientWasteNotifications(deletid.id));
  //   setDelet(false);
  // };

  // search
  const handleSearch = (ele: any) => {
    let item = ele.value.toLowerCase();

    if (item === "All Tasks") {
      setWasteNotifications([...walletHistories]);
    } else {
      handleSearchData({
        data: walletHistories,
        item: item,
        setState: setWasteNotifications,
      });
    }
  };

  // edit data

  const [editWasteNotifications, setEditWasteNotifications] =
    useState<boolean>(false);

  const [chatWasteNotifications, setChatWasteNotifications] =
    useState<boolean>(false);
  const [edit, setEdit] = useState<any>();
  const handleCloseChat = () => setChatWasteNotifications(false);

  const handleCloseEdit = () => setEditWasteNotifications(false);
  const handleEditWasteNotifications = (item: any) => {
    setEditWasteNotifications(true);
    setEdit({
      id: item.id,
      wasteNotificationId: item.wasteNotificationId,
      clientImage: item.clientImage,
      clientName: item.clientName,
      email: item.email,
      date: item.date,
      billed: item.billed,
      status: item.status,
    });
  };

  const handleChatWasteNotifications = (item: any) => {
    setChatWasteNotifications(true);
  };

  interface columnsType {
    Header: any;
    accessor: string;
    key?: string;
    Filter: boolean;
    isSortable: boolean;
    Cell?: (cell: any) => any;
  }

  const onRowClick = (cell: any) => {
    console.log("cell", cell);
    if (cell.row.original?.visitRequisition) {
      navigate(
        `/waste-notification-details/${cell.row.original?.visitRequisition?.id}`
      );
    } else if (cell.row.original?.bill) {
      navigate(`/waste-notification-details/${cell.row.original?.bill?.id}`);
    }
  };

  const columns: columnsType[] = useMemo(
    () => [
      {
        Header: () => (
          <div className="d-flex">
            <Form className="me-2">
              <Form.Check type="checkbox" />
            </Form>
            <div>PAYMENT REF NO.</div>
          </div>
        ),
        accessor: "id",
        key: "id",
        Filter: false,
        isSortable: false,
        width: 50,
        Cell: (cell: any) => (
          <div className="d-flex">
            <Form className="me-2">
              <Form.Check type="checkbox" />
            </Form>
            <Link
              className="text-body"
              to={`/waste-notification-details/${cell.row.original.id}`}
            >
              {String(cell.row.original?.id).substring(0, 13).toUpperCase()}
            </Link>
          </div>
        ),
      },
      {
        Header: "COMMENT",
        accessor: "comment",
        Filter: false,
        isSortable: true,
      },
      {
        Header: "AMOUNT CHARGED",
        accessor: "amount",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          const item = cell.row.original;
          return (
            <Link className="text-body" to={`#`}>
              {`₦${(+cell.row.original?.amount || 0).toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 2,
                }
              )}`}
            </Link>
          );
        },
      },
      {
        Header: "EVENT",
        accessor: "event",
        Filter: false,
        isSortable: true,
        Cell: (cell: any) => {
          switch (cell.row.original.event) {
            case WalletEventEnum.IN_FLOW:
              return (
                <span className="badge bg-success-subtle text-success p-2">
                  {cell.row.original.event}
                </span>
              );
            default:
              return (
                <span className="badge bg-warning-subtle text-warning p-2">
                  {cell.row.original.event}
                </span>
              );
          }
        },
      },
    ],
    [handleDeleteModal]
  );

  return (
    <React.Fragment>
      <Row className="pb-4 gy-3">
        <Col sm={4}>
          {company.companyType === CompanyTypeEnum.OIL_AND_GAS_PLATFORM ||
          company.companyType === CompanyTypeEnum.SHIPMENT ? (
            <button
              // to="/waste-notification-add"
              className="btn btn-primary addMembers-modal"
              onClick={handlePaymentWasteNotifications}
            >
              <i className="las la-plus me-1"></i> Add Money
            </button>
          ) : null}
        </Col>

        <div className="col-sm-auto ms-auto">
          <div className="d-flex gap-3">
            <div className="search-box">
              <Form.Control
                type="text"
                placeholder="Search for transaction..."
                onChange={(e: any) => handleSearch(e.target)}
              />
              <i className="las la-search search-icon"></i>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                className="btn btn-soft-info btn-icon fs-14 arrow-none"
              >
                <i className="las la-ellipsis-v fs-18"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Print</Dropdown.Item>
                <Dropdown.Item>Export to Excel</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Row>

      <WasteNotificationWidget />

      <Row>
        <Col xl={12}>
          <Card>
            <Card.Body>
              {wasteNotifications && wasteNotifications.length > 0 ? (
                <TableContainer
                  onRowClick={onRowClick}
                  isPagination={true}
                  columns={columns}
                  data={wasteNotifications || []}
                  customPageSize={9}
                  divClassName="table-card table-responsive"
                  tableClass="table-hover table-nowrap align-middle mb-0"
                  isBordered={false}
                  PaginationClass="align-items-center mt-4 gy-3"
                />
              ) : (
                <NoSearchResult />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Delete */}

      {/* <DeleteModal
        show={delet}
        handleClose={handleDeleteModal}
        deleteModalFunction={handleDeleteId}
      /> */}
      <ToastContainer />

      <EditWasteNotification
        isShow={editWasteNotifications}
        handleClose={handleCloseEdit}
        edit={edit}
      />

      <AddMoney
        amountDue={100000}
        isShow={paymentWasteNotifications}
        handlePayment={addMoney}
        handleClose={handleClosePayment}
        handleShow={handlePaymentWasteNotifications}
      />
      {/* <AddWasteNotificationInspections
        isShow={inspectionWasteNotifications}
        handleClose={handleCloseInspection}
        handleShow={handleInspectionWasteNotifications}
      /> */}
      {/* <AddWasteCollections
        isShow={collectionWasteNotifications}
        handleClose={handleCloseWasteCollection}
        handleShow={handleCollectionWasteNotifications}
      /> */}
      <WasteNotificationChatModal
        isShow={chatWasteNotifications}
        handleClose={handleCloseChat}
        handleShow={handleChatWasteNotifications}
      />
    </React.Fragment>
  );
};

export default WalletsTable;
