import { APIClient } from "./api_helper";
import { LoginDto } from "./models/auth.model";

import * as url from "./url_helper"

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Retrieve Industries Method
export const getIndustries = (url: any) => {
  return api.get(url, {})
    .catch((err: any) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Register Method
export const postJwtRegister = async (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};


// Login Method
export const postJwtLogin = (data: LoginDto) => api.create(url.POST_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export const addClientWasteNotifications = (data: any) => api.create(url.ADD_CLIENT_WASTE_NOTIFICATIONS, data);
export const getClientWasteNotifications = () => api.get(url.GET_CLIENT_WASTE_NOTIFICATIONS, null);
export const editClientWasteNotifications = (data: any, id: string) => api.update(`${url.EDIT_CLIENT_WASTE_NOTIFICATIONS}/${id}`, data);
export const deleteClientWasteNotifications = (id: any) => api.delete(url.DELETE_CLIENT_WASTE_NOTIFICATIONS, { headers: { id } });

export const getUsers = () => api.get(url.GET_USERS, null);
export const addUsers = (data: any) => api.create(url.ADD_USERS, data);
export const editUsers = (data: any) => api.update(url.EDIT_USERS, data);
export const deleteUsers = (id: any) => api.delete(url.DELETE_USERS, { headers: { id } });

export const getShips = () => api.get(url.GET_SHIPS, null);
export const addShips = (data: any) => api.create(url.ADD_SHIPS, data);
export const editShip = (data: any) => api.update(url.EDIT_SHIPS, data);
export const deleteShip = (id: any) => api.delete(url.DELETE_SHIPS, { headers: { id } });

export const getCompanies = () => api.get(url.GET_COMPANIES, null);
export const addCompanies = (data: any) => api.create(url.ADD_COMPANIES, data);
export const editCompany = (data: any) => api.update(url.EDIT_COMPANIES, data);
export const activateCompany = (data: any) => api.put(`${url.EDIT_COMPANIES}/${data?.id}/activate`, data);
export const deactivateCompany = (data: any) => api.put(`${url.EDIT_COMPANIES}/${data?.id}/deactivate`, data);
export const deleteCompany = (id: any) => api.delete(url.DELETE_COMPANIES, { headers: { id } });

export const getWalletMetrics = () => api.get(url.GET_WALLET_METRICS, null);
export const getWalletHistories = () => api.get(url.GET_WALLET_HISTORIES, null);
export const getWallets = () => api.get(url.GET_WALLETS, null);
export const addWallets = (data: any) => api.create(url.ADD_WALLETS, data);
export const editWallet = (data: any) => api.update(url.EDIT_WALLETS, data);
export const deleteWallet = (id: any) => api.delete(url.DELETE_WALLETS, { headers: { id } });

export const getOilPlatforms = () => api.get(url.GET_OIL_PLATFORMS, null);
export const addOilPlatforms = (data: any) => api.create(url.ADD_OIL_PLATFORMS, data);
export const editOilPlatform = (data: any, id: string) => api.update(`${url.EDIT_OIL_PLATFORMS}/${id}`, data);
export const deleteOilPlatform = (id: any) => api.delete(`${url.DELETE_OIL_PLATFORMS}/${id}`, { headers: { id } });

export const getRigs = () => api.get(url.GET_RIGS, null);
export const addRig = (data: any) => api.create(url.ADD_RIGS, data);
export const editRig = (data: any) => api.update(url.EDIT_RIGS, data);
export const deleteRig = (id: any) => api.delete(url.DELETE_RIGS, { headers: { id } });

export const getPortReceptions = (query?: any) => api.get(`${url.GET_PORT_RECEPTIONS}?${query}`, null);
export const addPortReception = (data: any) => api.create(url.ADD_PORT_RECEPTIONS, data);
export const editPortReception = (data: any) => api.update(url.EDIT_PORT_RECEPTIONS, data);
export const deletePortReception = (id: any) => api.delete(url.DELETE_PORT_RECEPTIONS, { headers: { id } });

export const getPortOfOperations = () => api.get(url.GET_PORT_OF_OPERATIONS, null);
export const addPortOfOperations = (data: any) => api.create(url.ADD_PORT_OF_OPERATIONS, data);
export const editPortOfOperations = (data: any, id: string) => api.update(`${url.EDIT_PORT_OF_OPERATIONS}/${id}`, data);
export const deletePortOfOperations = (id: any) => api.delete(url.DELETE_PORT_OF_OPERATIONS, { headers: { id } });

export const getWasteManagements = (query?: any) => api.get(`${url.GET_WASTE_MANAGEMENTS}?${query}`, null);
export const addWasteManagement = (data: any) => api.create(url.ADD_WASTE_MANAGEMENTS, data);
export const editWasteManagement = (data: any, id: string) => api.update(`${url.EDIT_WASTE_MANAGEMENTS}/${id}`, data);
export const deleteWasteManagement = (id: any) => api.delete(url.DELETE_WASTE_MANAGEMENTS, { headers: { id } });
