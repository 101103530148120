import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { CompanyTypeEnum } from "../helpers/enums/company.enum";

const Navdata = () => {
  //state data
  const [isInvoiceManagement, setIsInvoiceManagement] = useState(false);
  const [isWasteNotificationManagement, setIsWasteNotificationManagement] =
    useState(false);
  const [isFacilityManagement, setIsFacilityManagement] = useState(false);
  const [isShipManagement, setIsShipManagement] = useState(false);
  const [isOilPlatformManagement, setIsOilPlatformManagement] = useState(false);

  const [isAuthentication, setIsAuthentication] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isProducts, setIsProducts] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isTransaction, setIsTransaction] = useState(false);

  // Components
  const [isBootstrapUi, setIsBootstrapUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);

  const [isOrder, setIsOrder] = useState(false);

  const [isAuth, setIsAuth] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems: any = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id: any = item.getAttribute("sub-items");
        var menusId = document.getElementById(id);
        if (menusId) {
          (menusId.parentElement as HTMLElement).classList.remove("show");
        }
      });
      e.target.classList.add("active");
    }
  }

  const selectAccount = createSelector(
    (state: any) => state.Login,
    (account: any) => {
      // console.log("account", account);
      return {
        user: account.user,
      };
    }
  );

  const { user } = useSelector(selectAccount);

  const hasPermission = (requiredTypes: CompanyTypeEnum[]) => {
    // console.log("hasPermission user", user);
    return requiredTypes.includes(user?.company?.companyType);
  };

  const addLayoutData = (requiredTypes: CompanyTypeEnum[], child: any) => {
    return hasPermission(requiredTypes) ? [child] : [];
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "Invoice Management") {
      setIsInvoiceManagement(false);
    }

    if (iscurrentState !== "Authentication") {
      setIsAuthentication(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "Bootstrap UI") {
      setIsBootstrapUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    // if (iscurrentState !== 'Products') {
    //     setIsProducts(false);
    // }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }

    if (iscurrentState !== "Orders") {
      setIsOrder(false);
    }

    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
  }, [
    iscurrentState,
    isInvoiceManagement,
    isOrder,
    isAuth,
    isProducts,
    isReport,
    isMultiLevel,
    isAuthentication,
    isPages,
    isBootstrapUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
  ]);

  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "las la-house-damage",
      link: "/dashboard",
    },
    {
      label: "Pages",
      isHeader: true,
    },

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
      ],
      {
        id: "wasteNotificationManagement",
        label: "Waste Collections",
        icon: "las la-file-invoice",
        link: "/waste-notification",
        // click: function (e: any) {
        //   e.preventDefault();
        //   setIsWasteNotificationManagement(!isWasteNotificationManagement);
        //   setIscurrentState("Waste Declarations");
        //   updateIconSidebar(e);
        // },
        // stateVariables: isWasteNotificationManagement,
        // subItems: [
        //   {
        //     id: 1,
        //     label: "Waste Notification",
        //     link: "/waste-notification",
        //     parentId: "wasteNotificationManagement",
        //   },
        //   ...addLayoutData(
        //     [
        //       CompanyTypeEnum.PLATFORM_ADMIN,
        //       CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        //       CompanyTypeEnum.SHIPMENT,
        //     ],
        //     {
        //       id: 2,
        //       label: "Declare Waste",
        //       link: "/waste-notification-add",
        //       parentId: "wasteNotificationManagement",
        //     }
        //   ),
        //   // {
        //   //   id: 3,
        //   //   label: "Waste Notification Details",
        //   //   link: "/waste-notification-details",
        //   //   parentId: "wasteNotificationManagement",
        //   // },
        // ],
      }
    ),

    ...addLayoutData(
      [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
      {
        id: "wasteNotificationManagement",
        label: "Waste Declarations",
        icon: "las la-file-invoice",
        link: "/waste-notification",
      }
    ),

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
      ],
      {
        id: "userManagement",
        label: "User Management",
        icon: "las la-file-invoice",
        link: "/user",
      }
    ),

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
      ],
      {
        id: "companiesManagement",
        label: "Companies",
        icon: "las la-file-invoice",
        link: "/companies",
      }
    ),

    ...addLayoutData(
      [CompanyTypeEnum.PLATFORM_ADMIN, CompanyTypeEnum.SHIPMENT],
      {
        id: "shipManagement",
        label: "Ship Management",
        icon: "las la-file-invoice",
        link: "/ships",
      }
    ),

    ...addLayoutData(
      [CompanyTypeEnum.PLATFORM_ADMIN, CompanyTypeEnum.OIL_AND_GAS_PLATFORM],
      {
        id: "oilPlatformManagement",
        label: "Platform Management",
        icon: "las la-file-invoice",
        link: "/oil-platforms",
        // click: function (e: any) {
        //   e.preventDefault();
        //   setIsOilPlatformManagement(!isOilPlatformManagement);
        //   setIscurrentState("Platform Management");
        //   updateIconSidebar(e);
        // },
        // stateVariables: isOilPlatformManagement,
        // subItems: [
        //   {
        //     id: 1,
        //     label: "Oil Platforms",
        //     link: "/oil-platforms",
        //     parentId: "oilPlatformManagement",
        //   },
        //   {
        //     id: 2,
        //     label: "Rigs",
        //     link: "/rigs",
        //     parentId: "oilPlatformManagement",
        //   },
        // ],
      }
    ),

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
      ],
      {
        id: "facilityManagement1",
        label: "Facility Management",
        icon: "las la-file-invoice",
        link: "/waste-managements",
      }
    ),

    ...addLayoutData(
      [CompanyTypeEnum.PLATFORM_ADMIN, CompanyTypeEnum.NIGERIA_PORT_AUTHORITY],
      {
        id: "facilityManagement2",
        // label: "Facility Management",
        label: "Port of Operations",
        icon: "las la-file-invoice",
        link: "/port-of-operations",
      }
    ),

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
      ],
      {
        id: "facilityManagement3",
        label: "Facility Management",
        // label: "Port Receptions",
        icon: "las la-file-invoice",
        link: "/port-receptions",
      }
    ),

    // ...addLayoutData(
    //   [
    //     CompanyTypeEnum.PLATFORM_ADMIN,
    //     CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
    //     CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
    //     CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
    //   ],
    //   {
    //     id: "facilityManagement2",
    //     label: "Facility Management",
    //     icon: "las la-file-invoice",
    //     link: "/#",
    //     click: function (e: any) {
    //       e.preventDefault();
    //       setIsFacilityManagement(!isFacilityManagement);
    //       setIscurrentState("Facility Management");
    //       updateIconSidebar(e);
    //     },
    //     stateVariables: isFacilityManagement,
    //     subItems: [
    //       ...addLayoutData(
    //         [
    //           CompanyTypeEnum.PLATFORM_ADMIN,
    //           CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
    //         ],
    //         {
    //           id: 1,
    //           label: "Port Receptions",
    //           link: "/port-receptions",
    //           parentId: "facilityManagement",
    //         }
    //       ),
    //       ...addLayoutData(
    //         [
    //           CompanyTypeEnum.PLATFORM_ADMIN,
    //           CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
    //         ],
    //         {
    //           id: 2,
    //           label: "Port of Operations",
    //           link: "/port-of-operations",
    //           parentId: "facilityManagement",
    //         }
    //       ),
    //       ...addLayoutData(
    //         [
    //           CompanyTypeEnum.PLATFORM_ADMIN,
    //           CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
    //         ],
    //         {
    //           id: 3,
    //           label: "Waste Managements",
    //           link: "/waste-managements",
    //           parentId: "facilityManagement",
    //         }
    //       ),
    //     ],
    //   }
    // ),

    ...addLayoutData(
      [CompanyTypeEnum.OIL_AND_GAS_PLATFORM, CompanyTypeEnum.SHIPMENT],
      {
        id: "bill",
        label: "Bill",
        icon: "las la-file-invoice",
        link: "/bill",
      }
    ),

    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.SHIPMENT,
        CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
        CompanyTypeEnum.NIGERIA_PORT_AUTHORITY,
        CompanyTypeEnum.NIGERIA_MARITIME_ADMINISTRATION_AND_SAFETY_AGENCY,
        CompanyTypeEnum.PORT_RECEPTION_FACILITIES,
        CompanyTypeEnum.WASTE_MANAGEMENT_PROCESSING,
      ],
      {
        id: "wallet",
        label: "Wallet",
        icon: "las la-wallet",
        link: "/wallets",
      }
    ),
    ...addLayoutData(
      [
        CompanyTypeEnum.PLATFORM_ADMIN,
        CompanyTypeEnum.SHIPMENT,
        CompanyTypeEnum.OIL_AND_GAS_PLATFORM,
      ],
      {
        id: "reporting",
        label: "Garbage Book",
        icon: "las la-book",
        link: "/reporting",
      }
    ),
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
