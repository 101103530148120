import React from "react";
import BreadCrumb from "../../../Common/BreadCrumb";
import { Container } from "react-bootstrap";
import BillTable from "./BillTable";

const Bills = () => {
  document.title = "Bills | Maritime Waste Management Admin & Dashboard Platform";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Bills" title="Bills" />
          <BillTable />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Bills;
