import React, { useState } from "react";
import PortReceptionTable from "./PortReceptionTable";
import { Container } from "react-bootstrap";
import BreadCrumb from "../../../Common/BreadCrumb";

const PortReceptions = () => {
  document.title = "PortReception | Maritime Waste Management Admin & Dashboard Platform";

  const [isShow, setIsShow] = useState(false);

  const hidePortReceptionModal = () => {
    setIsShow(!isShow);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb pageTitle="Facility Managements" title="Facility Managements" />
          <PortReceptionTable isShow={isShow} hidePortReceptionModal={hidePortReceptionModal} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PortReceptions;
