import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
// import { addWasteNotificationInspections as onAddWasteNotificationInspections } from "../../slices/thunk";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment";
import FlatPickr from "react-flatpickr";
import dummy from "../../assets/images/users/user-dummy-img.jpg";
import { PatternFormat } from "react-number-format";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { getPortOfOperations as onGetPortOfOperations } from "../../slices/thunk";
import Dropzone from "react-dropzone";

interface userProps {
  isShow: boolean;
  handleClose: any;
  handleShow: any;
  handleInitiatedInspection: (data: any, resetFunc: any) => Promise<void>;
}

const AddWasteNotificationInspections = ({
  isShow,
  handleClose,
  handleShow,
  handleInitiatedInspection,
}: userProps) => {
  // image
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedFiles, setselectedFiles] = useState<any>([]);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      formik.setFieldValue("logo", e.target.result);
      setSelectedImage(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  const dispatch = useDispatch();

  const selectPortOfOperationsList = createSelector(
    (state: any) => state.Facility,
    (portOfOperations: any) => ({
      portOfOperationsList: portOfOperations.portOfOperationsList,
    })
  );

  const { portOfOperationsList } = useSelector(selectPortOfOperationsList);

  const [portOfOperations, setPortOfOperations] = useState<any>([]);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      title: "",
      findings: "",
      comments: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Inspection title"),
      findings: Yup.string().required("Please Enter Your Findings"),
      comments: Yup.string().required("Please Enter Your Comments"),
      // pooId: Yup.string().required("Please Select Port of Operation"),
    }),

    onSubmit: (values: any) => {
      // let newid = Math.floor(Math.random() * (30 - 20)) + 20;
      const NewWasteNotificationInspection = {
        // id: newid,
        title: values["title"],
        findings: values["findings"],
        // logo: values["logo"],
        comments: values["comments"],
        // address: values["address"],
        // latitude: values["latitude"],
        // longitude: values["longitude"],
        // state: values["state"],
        // country: values["country"],
      };

      handleInitiatedInspection(NewWasteNotificationInspection, () => formik.resetForm())
      // dispatch(onAddWasteNotificationInspections(NewWasteNotificationInspection));
      // formik.resetForm();

      if (NewWasteNotificationInspection === null) {
        handleShow();
      } else {
        handleClose();
      }
    },
  });

  useEffect(() => {
    setSelectedImage("");
  }, [handleClose]);

  useEffect(() => {
    dispatch(onGetPortOfOperations());
  }, [dispatch]);

  useEffect(() => {
    setPortOfOperations(portOfOperationsList);
  }, [portOfOperationsList]);

  return (
    <React.Fragment>
      <Modal
        centered
        show={isShow}
        onHide={handleClose}
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-content border-0">
          <Modal.Header className="p-4 pb-0">
            <Modal.Title as="h5">Add Inspections</Modal.Title>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </Modal.Header>
          <Modal.Body className="p-4">
            <Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <Form.Label htmlFor="users">Title</Form.Label>
                <Form.Control
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter Inspection title"
                  value={formik.values.title || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.users}
                />
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="users">Findings</Form.Label>
                <Form.Control
                  id="findings"
                  name="findings"
                  as="textarea"
                  className="form-control"
                  placeholder="Enter Your Findings"
                  value={formik.values.findings || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.findings && formik.touched.findings ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.findings}
                  </Form.Control.Feedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Form.Label htmlFor="Comments">Comments</Form.Label>
                <Form.Control
                  id="comments"
                  as="textarea"
                  name="comments"
                  placeholder="Enter comments"
                  value={formik.values.comments || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.comments}
                />
                {formik.errors.comments && formik.touched.comments ? (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {formik.errors.comments}
                  </Form.Control.Feedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="attachment">Attachments</Form.Label>
                <Dropzone
                  onDrop={(acceptedFiles: any) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps }: any) => (
                    <div className="dropzone dz-clickable text-center">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="hstack gap-2 justify-content-end">
                <Button
                  type="button"
                  className="btn btn-light"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button type="submit" className="btn btn-success">
                  Confirm Inspection
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddWasteNotificationInspections;
