import axios from "axios";
import getChartColorsArray from "../../Common/ChartsDynamicColor";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
import { GET_CLIENT_WASTE_NOTIFICATIONS } from "../../helpers/url_helper";

const WasteChart = ({ dataColors }: any) => {
  const [state, setState] = useState<{ series: any[]; categories: string[] }>({
    series: [],
    categories: [],
  });

  const navigate = useNavigate();

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
    })
  );
  const { tokens } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_CLIENT_WASTE_NOTIFICATIONS}/waste-metrics`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        console.log("response", JSON.parse(JSON.stringify(response)));
        setState(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          // toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate, tokens]);

  var chartColumnColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: "LIQUID OILY WASTE FROM SHIP MACHINERY",
      data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
    },
    {
      name: "SEWAGE",
      data: [74, 83, 102, 97, 86, 106, 93, 114, 94],
    },
    {
      name: "GARBAGE",
      data: [37, 42, 38, 26, 47, 50, 54, 55, 43],
    },
    {
      name: "OILY CARGO RESIDUE",
      data: [74, 83, 102, 97, 86, 50, 54, 55, 43],
    },
    {
      name: "HAZARDOUS WASTE",
      data: [50, 54, 55, 43, 37, 42, 38, 26],
    },
  ];

  var options: any = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: chartColumnColors,
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        text: "₦ (thousands)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "₦ " + val + " thousands";
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        series={series}
        options={options}
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export default WasteChart;
