//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";
export const GET_INDUSTRIES = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//LOGIN
export const POST_JWT_LOGIN = "/auth/api-token-auth";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

//Waste notifications
export const ADD_CLIENT_WASTE_NOTIFICATIONS = "/waste-notification";
export const GET_CLIENT_WASTE_NOTIFICATIONS = "/waste-notification";
export const DELETE_CLIENT_WASTE_NOTIFICATIONS = "/waste-notification";
export const EDIT_CLIENT_WASTE_NOTIFICATIONS = "/waste-notification";

//Bills
export const GET_BILLS = "/bills";

//Users
export const GET_USERS = "/users";
export const ADD_USERS = "/users";
export const EDIT_USERS = "/users";
export const DELETE_USERS = "/users";
export const NPA_USERS = "/users";
export const NIMASA_USERS = "/users";

//Ships
export const GET_SHIPS = "/ship";
export const ADD_SHIPS = "/ship";
export const EDIT_SHIPS = "/ship";
export const DELETE_SHIPS = "/ship";

//Companies
export const GET_COMPANIES = "/company";
export const ADD_COMPANIES = "/company";
export const EDIT_COMPANIES = "/company";
export const DELETE_COMPANIES = "/company";

//WALLETS
export const GET_WALLET_METRICS = "/waste-notification/wallet-metrics";
export const GET_WALLET_HISTORIES = "/waste-notification/wallet-history";
export const GET_WALLETS = "/waste-notification/wallet-balance";
export const ADD_WALLETS = "/waste-notification/add-money";
export const EDIT_WALLETS = "/waste-notification/wallet";
export const DELETE_WALLETS = "/waste-notification/wallet";

//OilPlatform
export const GET_OIL_PLATFORMS = "/platform";
export const ADD_OIL_PLATFORMS = "/platform";
export const EDIT_OIL_PLATFORMS = "/platform";
export const DELETE_OIL_PLATFORMS = "/platform";

//Oil and Gas Rigs
export const GET_RIGS = "/platform/rig";
export const ADD_RIGS = "/platform/rig";
export const EDIT_RIGS = "/platform/rig";
export const DELETE_RIGS = "/platform/rig";

//Port Receptions
export const GET_PORT_RECEPTIONS = "/prf";
export const ADD_PORT_RECEPTIONS = "/prf";
export const EDIT_PORT_RECEPTIONS = "/prf";
export const DELETE_PORT_RECEPTIONS = "/prf";

//Port Receptions
export const GET_PORT_OF_OPERATIONS = "/company/port-of-operations";
export const ADD_PORT_OF_OPERATIONS = "/company/port-of-operations";
export const EDIT_PORT_OF_OPERATIONS = "/company/port-of-operations";
export const DELETE_PORT_OF_OPERATIONS = "/company/port-of-operations";

//Waste Managements
export const GET_WASTE_MANAGEMENTS = "/wmp";
export const ADD_WASTE_MANAGEMENTS = "/wmp";
export const EDIT_WASTE_MANAGEMENTS = "/wmp";
export const DELETE_WASTE_MANAGEMENTS = "/wmp";
