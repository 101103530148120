import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, Row, Image, Button, Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// import Images
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar8 from "../assets/images/users/avatar-8.jpg";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { GET_BILLS, GET_CLIENT_WASTE_NOTIFICATIONS } from "../helpers/url_helper";

interface BillRecentActivitiesProps {
  //   isShow: any;
  //   handleClose: any;
  state: any;
}

const BillRecentActivities = ({ state }: BillRecentActivitiesProps) => {
  const [data, setData] = useState<any[]>();
  const navigate = useNavigate();

  const params = useParams();

  const selectAccountAndLogin = createSelector(
    (state: any) => state.Account,
    (state: any) => state.Login,
    (account: any, login: any) => ({
      tokens: login.tokens,
      company: login.user.company,
      loggedInUser: login.user,
    })
  );
  const { tokens, company } = useSelector(selectAccountAndLogin);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${GET_BILLS}/history/${params.id}`,
          { headers: { Authorization: `Bearer ${tokens?.accessToken}` } }
        );
        // console.log("response", JSON.parse(JSON.stringify(response)));
        setData(JSON.parse(JSON.stringify(response)));
      } catch (error: any) {
        // console.error("error", error);
        console.log("typeof error", typeof error);
        if (typeof error == "string" && error.includes("401")) {
          const message = "Invalid or expired token";
          console.log("message", message);
          toast.error(message, { autoClose: 2000 });

          navigate(process.env.PUBLIC_URL + "/logout");
        }
      }
    };

    getData();
  }, [navigate, params.id, tokens]);

  return (
    <Col className="pt-5">
      <hr />
      <h4 className="card-title mb-0 pt-5">RECENT ACTIVITIES</h4>

      {/* <Row>
        <Col lg={12}> */}
      {/* <Card.Body className="card-body p-4"> */}
      <Card.Body className="card-body pt-4 pb-4">
        <div className="table-responsive">
          <Table className="table-borderless text-center table-nowrap align-middle mb-0">
            <thead>
              <tr className="table-active">
                <th scope="col" className="text-start">
                  Date
                </th>
                <th scope="col" className="text-start">
                  Event
                </th>
                <th scope="col" className="text-start">
                  Details
                </th>
              </tr>
            </thead>
            <tbody id="products-list">
              {data &&
                data.map((wd: any, index: number) => (
                  <tr key={wd.id}>
                    <td className="text-start border">
                      {moment(wd.createDateTime).format("MMM Do YYYY, h:mm a")}
                    </td>
                    <td className="border">{wd.event}</td>
                    <td className="border text-start">{wd.comment}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Card.Body>
      {/* </Col>
      </Row> */}
    </Col>
  );
};

export default BillRecentActivities;
